<template>
  <div class="statistics-plan">
    <div class="container">
      <h2>Statitstics Plans</h2>
      <div style="overflow-x: auto">
        <table>
            <tr>
              <td>#</td>
              <td v-for="(plan, i) in plans">{{ plan.name }}</td>
              <td>total</td>
            </tr>
            <tr v-for="(meal, i) in meals">
              <td >{{ meal.name }}</td>
              <td v-for="(count , i) in meal.st">{{ count.count }}</td>
              <td >{{ meal.total }}</td>
            </tr>
        </table>
      </div>
    </div>
  </div>
  <ul>
    <!-- <li v-for="(meal, i) in meals">{{ meal.name }}</li> -->
  </ul>
</template>

<script>
import axios from "axios";
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://milddiets.com/back-end/api";
}

export default {
  data() {
    return {
      plans: [],
      meals: [],
    };
  },

  methods: {},

  mounted() {
  
    axios.get(`${urlSite}/dashbord`).then((res) => {
   
      this.meals = res.data.data.meals;
      this.plans = res.data.data.plans;
    });

  },
};
</script>

<style scope>
.statistics-plan table, .statistics-plan td {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
  white-space: nowrap;
  margin-top: 40px;
}

.statistics-plan table tr:first-of-type {
  background-color: #333;
  color: #fff;
}

.statistics-plan th, .statistics-plan td {
  text-align: left;
  padding: 8px;
}

.statistics-plan tr:nth-child(even) {
  background-color: #f2f2f2;
}
</style>
