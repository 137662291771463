
<template>
  <div class="container">
  
    <div class="row">
                <div class="col-md-6">

                  <div class="card mb-4">
                    <h5 class="card-header">تحديث الملف الشخصي</h5>
                    <!-- Account -->
                    <div class="card-body">
                      <div class="d-flex align-items-start align-items-sm-center gap-4">
                        
                        <img  v-if="imageUrl" :src="imageUrl" class="d-block rounded" height="100" width="100"/>

                        <img v-else :src="`img/${getPicture}`" alt="user-avatar" class="d-block rounded" height="100" width="100" id="uploadedAvatar">
                        
                        <div class="button-wrapper">
                          <label for="upload" class="btn btn-primary me-2 mb-4" tabindex="0">
                            <span class="d-none d-sm-block">صورة الملف الشخصي</span>
                            <i class="bx bx-upload d-block d-sm-none"></i>
                            <input type="file" id="upload" class="account-file-input" hidden="" @change="uploadPicture" accept="image/png, image/jpeg">
                          </label>
  

                          <p class="text-muted mb-0">Allowed JPG, GIF or PNG. Max size of 800K</p>
                        </div>
                      </div>
                    </div>
                    <hr class="my-0">
                    <div class="card-body">
                      <input type="hidden" v-model="user_id">

                      <form id="formAccountSettings" method="POST" @submit.prevent="" @submit="update()">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label for="firstName" class="form-label">اسم المستخدم</label>
                            <input  class="form-control" 
                                    type="text" 
                                    required
                                    v-model="getUsername" 
                                    autofocus=""
                            />
                          </div>

                          <div class="mb-3 col-md-12">
                            <label for="email" class="form-label">البريد الالكتروني</label>
                            <input  class="form-control" 
                                    type="email" 
                                    required
                                    v-model="getEmail" 
                                    placeholder="john.doe@example.com"
                            />
                          </div>

                          <div class="mb-3 col-md-12">
                            <label for="email" class="form-label">رقم الهاتف</label>
                            <input  class="form-control" 
                                    type="number" 
                                    required
                                    v-model="getPhone" 
                                    placeholder="054789006"
                            />
                          </div>

                          <div class="mb-3 col-md-12">
                            <label for="email" class="form-label"> المدينه</label> 
                            <select required style="width: 100%;" v-model="getCity" class="form-control form-select" >
                              <option :value="0">اختر المدينة</option>
                              <option v-for="item in loopCity" :value="item.id" :key="item">{{ item.name }}</option>
                            </select>
                          </div>

                          <div class="mb-3 col-md-12">
                            <label for="email" class="form-label"> العنوان </label>
                            <input  class="form-control" 
                                    type="text" 
                                    required
                                    v-model="getAddress" 
                                    placeholder="054789006"
                            />
                          </div>

                          <div class="mb-3 col-md-12">
                            <label for="email" class="form-label">كلمة المرور</label>
                            <input  class="form-control" 
                                    type="text" 
                                    required
                                    v-model="getPassword" 
                                    placeholder=".........."
                            />
                          </div>

                        </div>
                        <div class="mt-2">
                          <button type="submit" class="btn btn-primary me-2">تحديث البيانات</button>
                        </div>
                      </form>
                    </div>
                    <!-- /Account -->
                  </div>

                </div>
              </div>

  </div> <!-- container -->   
</template>


<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://milddiets.com/back-end/api";
}
 export default {
  name: "Member",

  data() {
    return {
      loopCity: [],
      getUsername: null,
      getEmail: null,
      getPhone: null,
      getCity: null,
      getAddress: null,
      getPassword: null,
      user_id : null,
      getPicture: null,
      imageUrl: null,
      picture: null,
      pictureName: null,
    }
  },



  mounted () {
      window.document.title = " الملف الشخصي ";
      if (!localStorage.getItem("status_auth")) {
          window.location.href = '/login';
      }
      this.user_id = localStorage.getItem("user_id");
      this.getData();
  }, // mounted

  methods : {
    uploadPicture(e){
      this.picture = e.target.files[0];
      this.imageUrl = URL.createObjectURL(e.target.files[0])
      this.getPicture = e.target.files[0].name;
    },
    async getData(){
      const response = await  axios.get(`${urlSite}/profile/${this.user_id}`);
      this.loopCity = response.data.city
      if(response.data.statusCode == 1) {
          this.getUsername  = response.data.data.name,
          this.getEmail     = response.data.data.email,
          this.getPhone     = response.data.data.phone,
          this.getCity      = response.data.data.city,
          this.getAddress   = response.data.data.address,
          this.getPassword  = response.data.data.password
          this.getPicture  = response.data.data.picture
      }      
    },





    async update () {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const formData = new FormData();
      formData.append("id", localStorage.getItem("user_id"));
      formData.append("name", this.getUsername);
      formData.append("email", this.getEmail);
      formData.append("phone", this.getPhone);
      formData.append("city", this.getCity);
      formData.append("address", this.getAddress);
      formData.append("password", this.getPassword);
      formData.append("picture", this.picture);
      formData.append("picture_name", this.pictureName);

      const response = await axios.post(`${urlSite}/update.my.acount`, formData);
      this.getPicture = response.data.name;
      Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'رسالة تاكيــــد',
          text: " تم تحديث البيانات بنجاح :)",
          showConfirmButton: false,
          timer: 2000
      })

    },









 }
}
</script>
   