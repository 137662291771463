
<template>


      
  <div class="container">
    <!-- Card Table -->
    <div class="card">
      <h5 class="card-header" v-if="$route.params.type == 'clint'"> العمــــــلاء   </h5>
      <h5 class="card-header" v-if="$route.params.type == 'delivery'">  عمال التوصيل    </h5>
      <h5 class="card-header" v-if="$route.params.type == 'muazaf'">الموظفين</h5>
      <h5 class="card-header" v-if="$route.params.type == 'admin'">الادارة</h5>
        <button type="button" 
                class="btn btn-primary btn-new btn-xs" 
                data-toggle="modal" 
                data-target="#newModal" 
                style="float: left;"
                >اضافة
        </button>
        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>الاســــــــــم</th>
                <th> البريد الالكتروني </th>
                <th> الهاتف </th>
                <th> المدينه </th>
                <th> العنوان </th>
                <th> كلمة المرور  </th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.name }}  </td>
                <td> {{ item.email }}  </td>
                <td> {{ item.phone }}  </td>
                <td> {{ item.city_name }}  </td>
                <td> {{ item.address }}  </td>
                <td> {{ item.password }}  </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.name, item.email, item.phone, item.city, item.address, item.password)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" v-if="this.$route.params.type == 'clint'">اسم العميل</label>
                  <label class="col-sm-2 col-form-label" v-if="this.$route.params.type == 'delivery'"> اسم عامل التوصيل</label>
                  <label class="col-sm-2 col-form-label" v-if="this.$route.params.type == 'muazaf'"> اسم الموظف</label>
                  <label class="col-sm-2 col-form-label" v-if="this.$route.params.type == 'admin'"> اسم الادمن</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="basic-default-name" v-model="name">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> البريد الالكتروني  </label>
                  <div class="col-sm-10">
                    <input type="email" class="form-control" id="basic-default-name" v-model="email" placeholder=" E-mail  ">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> رقم الهاتف </label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" v-model="phone" placeholder="0545783763">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> المدينة  </label>
                  <div class="col-sm-10">
                    <select style="width: 100%;" v-model="city" class="form-control form-select" >
                      <option value="">اختـــــر المدينة</option>
                      <option v-for="item in loopCity" :value="item.id" v-bind:key="item">{{ item.name }}</option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> العنوان  </label>
                  <div class="col-sm-10">
                      <input type="text" class="form-control" v-model="address">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name">  كلمة المرور  </label>
                  <div class="col-sm-10">
                    <input type="password" class="form-control" id="basic-default-name" v-model="password" placeholder="********">
                  </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Create Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">
              <input type="hidden"  v-model="editID" >
              <div class="row mb-3">
                <label class="col-sm-2 col-form-label" v-if="this.$route.params.type == 'clint'">اسم العميل</label>
                  <label class="col-sm-2 col-form-label" v-if="this.$route.params.type == 'delivery'"> اسم عامل التوصيل</label>
                  <label class="col-sm-2 col-form-label" v-if="this.$route.params.type == 'muazaf'"> اسم الموظف</label>
                  <label class="col-sm-2 col-form-label" v-if="this.$route.params.type == 'admin'"> اسم الادمن</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="basic-default-name" v-model="editName" >
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> البريد الالكتروني  </label>
                  <div class="col-sm-10">
                    <input type="email" class="form-control" id="basic-default-name" v-model="editEmail" placeholder=" E-mail  ">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> رقم الهاتف </label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" v-model="editPhone" placeholder="0545783763">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> المدينة  </label>
                  <div class="col-sm-10">
                    <select style="width: 100%;" v-model="editCity" class="form-control form-select" >
                      <option value="">اختـــــر المدينة</option>
                      <option v-for="item in loopCity" :value="item.id" v-bind:key="item">{{ item.name }}</option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> العنوان  </label>
                  <div class="col-sm-10">
                      <input type="text" class="form-control" v-model="editAddress">
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name">  كلمة المرور  </label>
                  <div class="col-sm-10">
                    <input type="password" class="form-control" id="basic-default-name" v-model="editPassword" placeholder="********">
                  </div>
              </div>

            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->
  </div> <!-- container -->   
</template>


<script>
import axios from 'axios'
import { useRoute } from 'vue-router'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://milddiets.com/back-end/api";
}
const route = useRoute();
 export default {
  name: "Member",

  data() {
    return {
      loopData: [],
      loopCity: [],
      // Create Acount
      name: "",
      email: "",
      phone: "",
      city: "",
      address: "",
      password: "",
      // Edit Acount
      editID: "",
      editName: "",
      editEmail: "",
      editPhone: "",
      editCity: "",
      editAddress: "",
      editPassword: "",

      acount: this.$route.params.type

    }
  },
 
  mounted () {
      if(this.$route.params.type == "clint") {
        window.document.title = " العمــــــلاء ";    
      } else if(this.$route.params.type == "delivery") {
        window.document.title = " عمال التوصيل ";    
      }
      else if(this.$route.params.type == "muazaf") {
        window.document.title = " الموظفين ";    
      } else if(this.$route.params.type == "admin") {
        window.document.title = " الادارة ";    
      } else {

      }
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/login';
      }
      this.getData();
  }, // mounted

  watch: {
  '$route' () {
    console.log(this.$route.params.type )
    axios.get(`${urlSite}/member/${this.$route.params.type}`)
        .then(res => {
          this.loopData = res.data.data
          this.loopCity = res.data.city
        })
  }
},


  methods : {
    getData(){
      axios.get(`${urlSite}/member/${this.acount}`)
        .then(res => {
          this.loopData = res.data.data
          this.loopCity = res.data.city
        })
                
    },

    store  () {
      axios.post(`${urlSite}/store.acount`, {
        acount: this.$route.params.type,
        name: this.name, 
        email: this.email, 
        phone: this.phone, 
        city: this.city, 
        address: this.address, 
        password: this.password, 
        //acount: this.acount,
      })
      .then(res => {
          this.getData();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم التسجيل بنجاح',
            text: "تم انشاء الحساب بنجاح :)",
            showConfirmButton: false,
            timer: 1500
          })

      });
      
    },

    // Get Data For Edit
    openEditModel (getID, getName, getEmail, getPhone, getCity, getAddress, getPassword) {
      this.editID      = getID
      this.editName    = getName
      this.editEmail   = getEmail
      this.editPhone   = getPhone
      this.editCity    = getCity
      this.editAddress = getAddress
      this.editPassword  = getPassword
    }, // openEditModel

    update () {
      axios.post(`${urlSite}/update.acount`, {
        id:this.editID, 
        name: this.editName, 
        email: this.editEmail,
        phone: this.editPhone,
        city: this.editCity,
        address: this.editAddress,
        password:this.editPassword,
      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: ' تحديث البيانات ',
            text: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },

    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "users", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete






 }
}
</script>
   