
<template>
  <div class="container">
    <!-- Card Table -->
    <div class="card">
        <button type="button" 
                class="btn btn-primary btn-new btn-xs" 
                data-toggle="modal" 
                data-target="#newModal" 
                style="float: left;"
                >اضافة
        </button>
        <div class="table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>الاســــــــــم</th>
                <th> البريد الالكتروني </th>
                <th> الهاتف </th>
                <th> الرسالة </th>
                <th> الوقت </th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" v-bind:key="item.id">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.name }}  </td>
                <td> {{ item.email }}  </td>
                <td> {{ item.phone }}  </td>
                <td> {{ item.message }}  </td>
                <td> {{ item.time }}  </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->




  </div> <!-- container -->   
</template>


<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://milddiets.com/back-end/api";
}
import PlansValidation from '../validation/PlansValidation.js'
 export default {
  name: "foods-s",

  data() {
    return {
      loopData: [],
    }
  },

  mounted () {
      window.document.title = " رسائل التواصل ";
      if (!localStorage.getItem("status_auth")) {
          window.location.href = '/login';
      }
      this.getData();
  }, // mounted

  methods : {

    getData(){
      axios.get(`${urlSite}/contact`)
        .then(res => {
          this.loopData = res.data
        })
    },

    store  () {
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("price", this.price);
      formData.append("description", this.description);
      formData.append("arrayFood", this.arrayFood);
      formData.append("picture", this.uploadFilePicture.picture);
      axios.post(`${urlSite}/store.plan`, formData)
      .then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });

    },


    // Get Data For Edit
    openEditModel (getID, getName, getPrice, getDescription) {
      this.editID      = getID
      this.editName    = getName
      this.editPrice   = getPrice
      this.editDescription  = getDescription
    }, // openEditModel


    async update () {
      const formData = new FormData();
      formData.append("id", this.editID);
      formData.append("name", this.editName);
      formData.append("price", this.editPrice);
      formData.append("description", this.editDescription);
      formData.append("arrayFood", this.arrayFood);
      formData.append("picture", this.uploadFilePicture.picture);
      const response = await axios.post(`${urlSite}/update.plan`, formData)
      if(response) {
        this.getData();
        this.uploadFilePicture.picture = null;
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      }

    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "plans", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   