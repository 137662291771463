
<template>
  <div class="">
    <!-- Card Table -->
    <div class="card">

      <form @submit.prevent="" @submit="filter()" class=" searchBox">
          <nav class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
            <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
              <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
                <i class="bx bx-menu bx-sm"></i>
              </a>
            </div>
            <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
              <div class="navbar-nav align-items-center">
                <div class="nav-item d-flex align-items-center">
                  <i class="bx bx-search fs-4 lh-0"></i>
                  <input type="text" 
                         v-model="searchBox"
                         class="form-control border-0 shadow-none" 
                         placeholder="Search..." 
                         aria-label="Search..."
                  />
                </div>
              </div>
            </div>
            <select v-model="filter_city" id="exampleFormControlSelect1" class="form-control  form-select">
                <option value="0"> المدينة </option>
                <option :value="city.id" v-for="city in loopCity" :key="city">{{ city.name }}</option>
            </select>
            
            <select v-model="filter_status" class="form-select" id="exampleFormControlSelect1" aria-label="Default select example">
              <option value=""> الحالة </option>
              <option value="قيد التحضير">قيد التحضير</option>
              <option value="جاهز للتوصيل">جاهز للتوصيل</option>
              <option value="موجلة">موجلة</option>
            </select>
            <select v-model="filter_plan" class="form-select" >
                <option value="0"> الباقة </option>
                <option :value="plan.id" v-for="plan in loopPlans" :key="plan">{{ plan.name }}</option>
            </select>
            <button class="btn btn-dark " type="submit">بحث</button>
          </nav> 
    </form>



      <h5 class="card-header"> طلبات يوم 
          <span v-if="nameDay == 'sunday'">الاحد</span>
          <span v-if="nameDay == 'monday'">الاتنين</span>
          <span v-if="nameDay == 'tuesday'">الثلاثاء</span>
          <span v-if="nameDay == 'wednesday'">الاربع</span>
          <span v-if="nameDay == 'thursday'">الخميس</span>
          <span v-if="nameDay == 'friday'">الجمعه</span>
          <span v-if="nameDay == 'saturday'">السبت</span>
      </h5>


        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>الاســــــــــم</th>
                <th> الباقة </th>
                <th> الوجابات </th>
                <th> ملاحظات </th>
                <th> الحالة </th>
                <th>تغيير الحالة</th>
                <th>الديلفري </th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.id">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.username }}  </td>
                <td> {{ item.plan_name }}  </td>
                <td> {{ item.meals_name }} </td>
                <td> {{ item.note }}  </td>
                <td> 
                  <span class="badge bg-label-primary" v-if="item.status == 'قيد التحضير'">	قيد التحضير</span>
                  <span class="badge bg-label-primary" v-if="item.status == 'جاهز للتوصيل'">جاهز للتوصيل</span>
                  <span class="badge bg-label-danger" v-if="item.status == 'موجلة'">موجلة</span> 
                </td>
                <td>
                    <select @change="changeStatus($event, item.id)" v-model="status" class="form-select" id="exampleFormControlSelect1" aria-label="Default select example">
                      <option value=""></option>
                      <option value="قيد التحضير">قيد التحضير</option>
                      <option value="جاهز للتوصيل">جاهز للتوصيل</option>
                      <option value="موجلة">موجلة</option>
                    </select>
                </td>
                <td>
                  <i  class="btn-action fas fa-check"
                      v-if="item.status_done != 1"
                      :data-id="item.id" 
                      @click="createOrder">
                  </i>
                </td>
              </tr>
            </tbody>

            <tfoot>
              <pagination align="center" :data="Payment" @pagination-change-page="loopData"></pagination>

            </tfoot>
          </table>
        </div>
    </div>
    <!-- Card Table -->



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">

              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name">  العميل </label>
                  <div class="col-sm-10">
                    <select v-model="clint" class="form-control form-select">
                      <option value=""></option>
                      <option :value="clint.id" v-for="clint in loopClints" v-bind:key="clint">{{ clint.name }}</option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> الباقة  </label>
                  <div class="col-sm-10">
                    <select v-model="plan" class="form-control form-select">
                      <option value=""></option>
                      <option :value="plan.id" v-for="plan in loopPlans" v-bind:key="plan">{{ plan.name }}</option>
                    </select>
                  </div>
              </div>  

              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> الجرامات  </label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="basic-default-name" v-model="gram" />
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> ملاحظات </label>
                  <div class="col-sm-10">
                    <textarea name="" id="" cols="3" class="form-control" rows="3" v-model="note" ></textarea>
                  </div>
              </div> 
      
              <!-- الايــــام  -->
              <div class="row mb-3">
                <label class="col-sm-2 col-form-label" for="basic-default-name"> الايام </label>
                <div class="col-sm-10 row">
                  <div class="form-check form-switch mb-2 col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="sunday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الاحد</label>
                  </div>
                  <br>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="monday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الاثنين</label>
                  </div>
                  <div class="form-check form-switch mb-2 col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="tuesday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الثلاثاء</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="wednesday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الاربع</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="thursday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الخميس</label>
                  </div>
                  <div class="form-check form-switch mb-2 col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="friday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الجمعه</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="saturday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">السبت</label>
                  </div>
                </div>
              </div>
              <!-- الايــــام  -->


                    
              <!-- الايــــام  -->
              <div class="row mb-3">
                <label class="col-sm-2 col-form-label" for="basic-default-name"> الوجابات </label>
                <div class="col-sm-10 row">
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="breakfast" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الفطـور</label>
                  </div>
                  <div class="form-check form-switch mb-2 col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="lunch" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الغـذاء</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="dinner" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">العشـــــاء</label>
                  </div>
                </div>
              </div>
              <!-- الايــــام  -->


              <li class="text-center text-danger" v-for="error in formValdatorCreate" v-bind:key="error"> {{ error  }} <br></li>


            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Create Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">
              <input type="hidden"  v-model="editID" >

              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> اسم العميل </label>
                  <div class="col-sm-10">
                    <select v-model="editClint"  class="form-control form-select">
                      <option value=""></option>
                      <option :value="clint.id" v-for="clint in loopClints" v-bind:key="clint">{{ clint.name }}</option>
                    </select>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> الباقة  </label>
                  <div class="col-sm-10">
                    <select v-model="editPlan" class="form-control form-select">
                      <option value=""></option>
                      <option :value="plan.id" v-for="plan in loopPlans" v-bind:key="plan">{{ plan.name }}</option>
                    </select>
                  </div>
              </div>  

              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> الجرامات  </label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="basic-default-name" v-model="editGram" />
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> ملاحظات </label>
                  <div class="col-sm-10">
                    <textarea name="" id="" cols="3" class="form-control" rows="3" v-model="editNote" ></textarea>
                  </div>
              </div>


              <!-- الايــــام  -->
              <div class="row mb-3">
                <label class="col-sm-2 col-form-label" for="basic-default-name"> الايام </label>
                <div class="col-sm-10 row">

                  <div class="form-check form-switch mb-2 col-sm-4">
                      <input  class="form-check-input" 
                              type="checkbox" 
                              value="true" 
                              :checked="checked"
                              v-model="editSunday" 
                              id="flexSwitchCheckChecked_1"
                      />

                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الاحد</label>
                  </div>
                  <br>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-if="editMonday == 1" checked v-model="editMonday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الاثنين</label>
                  </div>
                  <div class="form-check form-switch mb-2 col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="editTuesday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الثلاثاء</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="editWednesday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الاربع</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="editThursday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الخميس</label>
                  </div>
                  <div class="form-check form-switch mb-2 col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="editFriday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الجمعه</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="editSaturday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">السبت</label>
                  </div>
                </div>
              </div>
              <!-- الايــــام  -->


                    
              <!-- الايــــام  -->
              <div class="row mb-3">
                <label class="col-sm-2 col-form-label" for="basic-default-name"> الوجابات </label>
                <div class="col-sm-10 row">
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="editBreakfast" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الفطـور</label>
                  </div>
                  <div class="form-check form-switch mb-2 col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="editLunch" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الغـذاء</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="editDinner" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">العشـــــاء</label>
                  </div>
                </div>
              </div>
              <!-- الايــــام  -->

              <li class="text-center text-danger" v-for="error in formValdatorCreate" v-bind:key="error"> {{ error  }} <br></li>


            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->

  </div> <!-- container -->   
</template>


<style>
.searchBox select, .searchBox input {
    max-width: 120px;
    margin-left: 10px;
}

label {
    display: inline-block;
    padding-right: 17px;
}

table .badge {
    font-size: 15px;
    /* font-weight: 900; */
    margin: 4px;
    padding: 6px 7px;
}


</style>






<script>
import axios from 'axios'
import pagination from 'laravel-vue-pagination'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://milddiets.com/back-end/api";
}
import PlansValidation from '../validation/PlansValidation.js'
 export default {
  name: "Payment",
  components:{
            pagination
        },
  data() {
    return {
      // Validation
      formValdatorCreate: [],
      statusValidation: false,

      // loop Data
      loopData: [],
      loopClints: [],
      loopPlans: [],
      loopBanks: [],
      loopCity: [],
      deliverys: [],

      // Create Row
      clint : "",
      plan : "",
      gram : "",
      note : "",
      // The Days
      sunday : "",
      monday : "",
      tuesday : "",
      wednesday : "",
      thursday : "",
      friday : "",
      saturday : "",
      // The meals
      breakfast : "",
      lunch : "",
      dinner : "",

       

      // Edit Row
      editID : "",
      editClint : "",
      editPlan : "", 
      editGram : "",
      editNote : "",
      // Days
      editSunday : "",
      editMonday : "",
      editTuesday : "",
      editWednesday : "",
      editThursday : "",
      editFriday : "",
      editSaturday : "",
      // The meals
      editBreakfast : "",
      editLunch : "",
      editDinner : "",

      filter_plan : "",
      filter_status : "",
      filter_city: "",
      
      searchBox: "",
      status: "",

    }
  },

  mounted () {
      window.document.title = "طلبات اليوم";
      if (!localStorage.getItem("status_auth")) {
          window.location.href = '/login';
      }
      this.getData();
  }, // mounted


  methods : {

    async createOrder () {
      const response = await axios.post(`${urlSite}/create.order`, {
        id : event.target.getAttribute('data-id'),
      });
      if(response) {
          this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اضافة الاوردر ال صفحة الطلبات  بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      }
    },

    async changeStatus(event, data) {
      const response = await axios.post(`${urlSite}/update.status.subscription.today`, {
        id : data,
        status : event.target.value
      })
      if(response) {
          this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم تحديث العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      }
    },

    filter () {
      axios.get(`${urlSite}/to.day`
      + "?status="    + this.filter_status 
      + "&plan="   + this.filter_plan      
      + "&city="   + this.filter_city
      + "&search=" + this.searchBox
      ) 
      .then(res => {
          this.loopData = res.data.subscription;
        })
    },

    getData(page=1){
      axios.get(`${urlSite}/to.day`)
        .then(res => {
          this.loopData   = res.data.subscription
          this.loopPlans  = res.data.plans
          this.loopCity   = res.data.city
          this.nameDay    = res.data.test
          this.deliverys  = res.data.delivery
        })

        
        
    },

    async store  () {
      // Valdation
      this.formValdatorCreate = [ ];
      if(!this.clint || !this.plan) {
        this.formValdatorCreate.push("يرجي ملئ الحقول المطلوبة");
        this.statusValidation = false
      } else {
        this.statusValidation = true;
      } // End Condation Valdator


      if(this.statusValidation == true) {
        const response = await axios.post(`${urlSite}/store.subscription`, {
            clint    : this.clint,
            plan     : this.plan,
            gram     : this.gram,
            note     : this.note,
            // Days
            sunday   : this.sunday,
            monday   : this.monday,
            tuesday  : this.tuesday,
            wednesday : this.wednesday,
            thursday : this.thursday,
            friday   : this.friday,
            saturday : this.saturday,
            // Days
            breakfast : this.breakfast,
            lunch     : this.lunch,
            dinner    : this.dinner,
          });

          if(response) {
          this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      } // response

      }

    }, // Store Function


    // Get Data For Edit
    openEditModel (getID, getClint, getPlan, editGram, getNote, getSunday, getMonday, getTuesday, getWednesday, getThursday ,getFriday, getSaturday, getBreakfast, getLunch, getDinner) {
      this.editID        = getID
      this.editClint     = getClint
      this.editPlan      = getPlan
      this.editGram      = editGram
      this.editNote      = getNote

      this.editSunday   = getSunday
      this.editMonday   = getMonday
      this.editTuesday  = getTuesday
      this.editWednesday = getWednesday
      this.editThursday = getThursday
      this.editFriday   = getFriday
      this.editSaturday = getSaturday
      // The meals
      this.editBreakfast = getBreakfast
      this.editLunch    = getLunch
      this.editDinner   = getDinner
    }, // openEditModel


    async update  () {
          const response = await axios.post(`${urlSite}/update.subscriptions`, {
           // muazaf   : localStorage.getItem("user_id"),
            id       : this.editID,
            clint    : this.editClint,
            plan     : this.editPlan,
            bank     : this.editBank,
            money    : this.editMoney,
            discount : this.editDiscount,
            note     : this.editNote,
          });
          if(response) {
          this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      } // response

    }, // Store Function


    onDelete(id) {
      swal({
        title: "هل انت متاكد من حذف البيانات ؟",
        text: "عند حذف البيانات لا يمكن استرجاعها",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "اكمل الاجراء",
        cancelButtonText: "تراجع",
        closeOnConfirm: false,
        closeOnCancel: false
      },
      function(isConfirm){
        if (isConfirm) {
          axios.post(`${urlSite}/destroy`, {
              id   : id, 
              table: "subscriptions", 
          }).then(res => {
            swal("رسالة تاكيد", "تم حذف البيانات بنجاح:)", "error");
            axios.get(`${urlSite}/subscription`)
            .then(res => {
                this.loopData = res.data
            })
          });
        } else {
          swal("اغـــلاق", "تم التراجع عن عملية الحذف :)", "error");
        }
      });
    }, // onDelete






 }
}
</script>
   