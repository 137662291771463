
<template>
  <div class="container">
    <!-- Card Table -->
    <div class="card">

      <form @submit.prevent="" @submit="filter()" class=" searchBox">
          <nav class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
            <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
              <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
                <i class="bx bx-menu bx-sm"></i>
              </a>
            </div>
            <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
              <div class="navbar-nav align-items-center">
                <div class="nav-item d-flex align-items-center">
                  <i class="bx bx-search fs-4 lh-0"></i>
                  <input type="text" 
                         v-model="searchBox"
                         class="form-control border-0 shadow-none" 
                         placeholder="Search..." 
                         aria-label="Search..."
                  />
                </div>
              </div>
            </div>

            <input type="date" class="form-control" v-model="filter_start">
            <input type="date" class="form-control" v-model="filter_end">


            <select v-model="filter_plan" class="form-select" >
                <option value="0"> الباقة </option>
                <option :value="plan.id" v-for="plan in loopPlans" v-bind:key="plan">{{ plan.name }}</option>
            </select>
            <button class="btn btn-dark " type="submit">بحث</button>
          </nav> 
    </form>



      <h5 class="card-header"> التحويلات البنكية </h5>

        <button type="button" 
                class="btn btn-primary btn-new btn-xs" 
                data-toggle="modal" 
                data-target="#newModal" 
                style="float: left;"
                >اضافة
        </button>

        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>الاســــــــــم</th>
                <th> الباقة </th>
                <th> المبلغ </th>
                <th>  الخصم </th>
                <th> بعد الخصم </th>
                <th> البنك </th>
                <th> تاريخ الحوالة </th>
                <th>الايام</th>
                <th>الوجبات</th>
                <th>طرق الاستلام</th>
                <th> ملاحظات </th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.clint }}  </td>
                <td> {{ item.plan_name }}  </td>
                <td> {{ item.money }}  </td>
                <td> {{ item.discount }} % </td>
                <td> {{ item.money - item.money * item.discount / 100 }}  </td>
                <td> {{ item.bank }}  </td>
                <td> {{ item.date }}  </td>
                <td> {{ item.days }}  </td>
                <td> {{ item.meals_name }}  </td>
                <td> {{ item.receiving_method }}  </td>
                <td> {{ item.note }}  </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.clint, item.plan, item.money, item.discount, item.bank, item.date, item.note, item.days, item.meals_count, item.receiving_method)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
    </div>
    <!-- Card Table -->




    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">

              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> اسم العميل </label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="basic-default-name" v-model="clint" />
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> الباقة  </label>
                  <div class="col-sm-10">
                    <select v-model="plan" class="form-control form-select">
                      <option value=""></option>
                      <option :value="plan.id" v-for="plan in loopPlans" v-bind:key="plan">{{ plan.name }}</option>
                    </select>
                  </div>
              </div>  
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> البنك </label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="basic-default-name" v-model="bank" />
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> المبلغ   </label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="basic-default-name" v-model="money" />
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> الخصم  </label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="basic-default-name" v-model="discount" />
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name">عدد الايام</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="basic-default-name" v-model="days" />
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name">توقيت الحوالة</label>
                  <div class="col-sm-10">
                    <input type="date" class="form-control" id="basic-default-name" v-model="date" />
                  </div>
              </div>



              <div class="row mb-3">
                <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name"> طريق الاستلام </label>
                      <select class="form-control" v-model="receiving_method">
                        <option value="استـــــلام من المركز">استـــــلام من المركز</option>
                        <option value="توصيــــــل">توصيــــــل</option>
                      </select>
                    </div>
                <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name">الوجبات</label>
                    <select class="form-control" v-model="meals_count">
                        <option value=""></option>
                        <option :value="item.id" v-for="item in loop_meals" :key="item">{{ item.name  }}</option>
                    </select>
                </div>
              </div> 



              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> ملاحظات </label>
                  <div class="col-sm-10">
                    <textarea name="" id="" cols="3" class="form-control" rows="3" v-model="note" ></textarea>
                  </div>
              </div> 



            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->







    <!-- Create Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">
              <input type="hidden"  v-model="editID" >


              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> اسم العميل </label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="basic-default-name" v-model="editClint" />
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> الباقة  </label>
                  <div class="col-sm-10">
                    <select v-model="plan" class="form-control form-select">
                      <option value=""></option>
                      <option :value="plan.id" v-for="plan in loopPlans" :key="item" v-bind:key="editPlan">{{ plan.name }}</option>
                    </select>
                  </div>
              </div>  
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> البنك </label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="basic-default-name" v-model="editBank" />
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> المبلغ   </label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="basic-default-name" v-model="editMoney" />
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> الخصم  </label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="basic-default-name" v-model="editDiscount" />
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name">عدد الايام</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="basic-default-name" v-model="editDays" />
                  </div>
              </div>

              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name">توقيت الحوالة</label>
                  <div class="col-sm-10">
                    <input type="date" class="form-control" id="basic-default-name" v-model="editDate" />
                  </div>
              </div>


              <div class="row mb-3">
                <div class="col-sm-6">
                      <label class="col-form-label" for="basic-default-name"> طريق الاستلام </label>
                      <select class="form-control" v-model="editReceiving_method">
                        <option value="استـــــلام من المركز">استـــــلام من المركز</option>
                        <option value="توصيــــــل">توصيــــــل</option>
                      </select>
                    </div>
                    <div class="col-sm-6">
                        <label class="col-form-label" for="basic-default-name">الوجبات</label>
                        <select class="form-control" v-model="editMeals_count">
                            <option value=""></option>
                            <option :value="item.id" v-for="item in loop_meals" :key="item">{{ item.name  }}</option>
                        </select>
                    </div>
              </div> 



              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> ملاحظات </label>
                  <div class="col-sm-10">
                    <textarea name="" id="" cols="3" class="form-control" rows="3" v-model="editNote" ></textarea>
                  </div>
              </div> 

            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->
  </div> <!-- container -->   
</template>









<script>
import axios from 'axios'
import pagination from 'laravel-vue-pagination'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://milddiets.com/back-end/api";
}
import PlansValidation from '../validation/PlansValidation.js'
 export default {
  name: "Payment",
  components:{
            pagination
        },
  data() {
    return {
      // Validation
      formValdatorCreate: [],
      statusValidation: false,

      // loop Data
      loopData    : [],
      loopClints  : [],
      loopPlans   : [],
      loopBanks   : [],
      loopCity    : [],
      loop_meals  : [],

      // Create Row
      clint: "",
      plan: "",
      bank: "",
      money: "",
      discount: "",
      date: "",
      note: "",
      days: "",
		  meals_count: "",
		  receiving_method: "",
      // Edit Row
      /*
      getClint: "",
      getPlan: "",
      getBank: "",
      getMoney: "",
      getDiscount: "",
      getNote: "",  
      */

      editID : "",
      editClint : "",
      editPlan : "", 
      editMoney : "",
      editDiscount : "",
      editBank : "",
      editDate: "",
      editNote : "",
      editDays: "",
		  editMeals_count: "",
		  editReceiving_method: "",

      filter_start: "",
      filter_end  : "",
      filter_bank : "",
      filter_plan : "",
      filter_clint: "",
      filter_city: "",
      
      searchBox: ""
    }
  },

  mounted () {
      window.document.title = " التحويلات البنكية ";
      if (!localStorage.getItem("status_auth")) {
          window.location.href = '/login';
      }
      this.getData();
  }, // mounted


  methods : {

    sendInofAcount () {
      console.log(localStorage.getItem("user_id"))
      axios.get(`${urlSite}/payment`
      + "?clint="    + this.filter_clint 
      + "&bank="   + this.filter_bank 
      + "&plan="   + this.filter_plan
      + "&start="  + this.filter_start
      + "&end="    + this.filter_end
      + "&city="   + this.filter_city
      + "&search=" + this.searchBox
      ) 
      .then(res => {
          this.loopData = res.data.payment;
        })
    },

    filter () {
      console.log(this.filter_bank)
      axios.get(`${urlSite}/payment`
      + "?clint="    + this.filter_clint 
      + "&bank="   + this.filter_bank 
      + "&plan="   + this.filter_plan
      + "&start="  + this.filter_start
      + "&end="    + this.filter_end
      + "&city="   + this.filter_city
      + "&search=" + this.searchBox
      ) 
      .then(res => {
          this.loopData = res.data.payment;
        })
    },

    getData(page=1){
      axios.get(`${urlSite}/payment`)
        .then(res => {
          this.loopData   = res.data.payment
          this.loopClints = res.data.clints
          this.loopPlans  = res.data.plans
          this.loopBanks  = res.data.banks
          this.loopCity   = res.data.city
          this.loop_meals = res.data.meals
        })
        
    },


    onChange(e) {
      console.log(e.target.files[0]);
      this.image = e.target.files[0].name;
      this.image = e.target.files[0];
    },

    async store  () {


        const response = await axios.post(`${urlSite}/store.payment`, {
            muazaf   : localStorage.getItem("user_id"),
            clint    : this.clint,
            plan     : this.plan,
            bank     : this.bank,
            money    : this.money,
            discount : this.discount,
            date     : this.date,
            note     : this.note,
            days     : this.days,
            meals_count : this.meals_count,
            receiving_method : this.receiving_method,
          });

          if(response) {
          this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      } // response



    }, // Store Function


    // Get Data For Edit
    openEditModel (getID, getClint, getPlan, getMoney, getDiscount, getBank, getDate, getNote, Days, Meals_count, Receiving_method) {
      this.editID        = getID
      this.editClint     = getClint
      this.editPlan      = getPlan
      this.editMoney     = getMoney
      this.editDiscount  = getDiscount
      this.editBank      = getBank
      this.editNote      = getNote
      this.editDate      = getDate
      this.editDays      = Days
      this.editMeals_count      = Meals_count
      this.editReceiving_method      = Receiving_method
    }, // openEditModel





    async update  () {
          const response = await axios.post(`${urlSite}/update.payment`, {
           // muazaf   : localStorage.getItem("user_id"),
            id       : this.editID,
            clint    : this.editClint,
            plan     : this.editPlan,
            bank     : this.editBank,
            money    : this.editMoney,
            discount : this.editDiscount,
            note     : this.editNote,
            note     : this.editNote,
            note     : this.editNote,
            note     : this.editNote,
            days:   this.editDays,
            meals_count: this.editMeals_count,
            receiving_method: this.editReceiving_method,
 
          });
          if(response) {
          this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      } // response

    }, // Store Function






    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "payment", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete





 }
}
</script>
   