t<script lang="ts">
export default {

  name: "MenuRight",
  data() {
      return {
        user_id     : localStorage.getItem("user_id"),
        status_auth : localStorage.getItem('status_auth'),
        acount_type: localStorage.getItem('acount_type'),
      }
  }, 

  methods : {
    logout () {
      localStorage.removeItem("user_id");
      localStorage.removeItem("status_auth");
      window.location.href = '/login';
    },
  }

  // filter Payment
  // Dashbord



}
</script>


<template>
        <!-- Menu -->
        <aside  id="layout-menu" v-if="$route.name != 'home' && $route.name != 'login' && $route.name != 'signup' && $route.name != 'plan'" style="display: block;"  class="layout-menu menu-vertical menu bg-menu-theme" data-bg-class="bg-menu-theme">
          <div class="app-brand demo">
            
            <a href="index.html" class="app-brand-link">
              <img src="../../public/assets/logo-brand.png" alt="">
            </a>
  
            <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
              <i class="bx bx-chevron-left bx-sm align-middle"></i>
            </a>
          </div>
  
          <div class="menu-inner-shadow"></div>
  
          <ul class="menu-inner py-1 ps ps__rtl">
            <li class="menu-item">
              <router-link to="/" class="menu-link">
                <i class="menu-icon tf-icons bx bx-bell"></i>
                <div data-i18n="Support"> الرئيسية </div>
              </router-link>
            </li>
            <!-- Dashboard -->
            <li class="menu-item" v-if="acount_type == 'admin'">
              <router-link to="/dashbord" class="menu-link">
                <i class="menu-icon tf-icons bx bx-bell"></i>
                <div data-i18n="Support"> لوحة الاحصائيات  </div>
              </router-link>
            </li>
            <li class="menu-item">
              <router-link to="/profile" class="menu-link">
                <i class="menu-icon tf-icons bx bx-user"></i>
                <div data-i18n="Support"> الملف الشخصي </div>
              </router-link>
            </li>    
  <!--
            <li class="menu-item">
              <router-link to="/notifications" class="menu-link">
                <i class="menu-icon tf-icons bx bx-bell"></i>
                <div data-i18n="Support"> الاشعــــــارات  </div>
              </router-link>
            </li>
            

            <li class="menu-item">
              <a href="/income.mechanism" class="menu-link">
                <i class="menu-icon tf-icons bx bx-bell"></i>
                <div data-i18n="Support"> آلية الدخل  </div>
              </a>
            </li>
             <li class="menu-item" v-if="acount_type == 'admin'">
               <router-link to="/plans" class="menu-link">
                 <i class="menu-icon tf-icons bx bx-user"></i>
                 <div data-i18n="Support"> الباقات الغذائية </div>
               </router-link>
             </li>            
-->
            <li class="menu-item" v-if="acount_type == 'admin'">
              <router-link to="/member/clint" class="menu-link">
                <i class="menu-icon tf-icons bx bx-bell"></i>
                <div data-i18n="Support"> العملاء  </div>
              </router-link>
            </li>

            <li class="menu-item" v-if="acount_type == 'admin'">
              <router-link to="/member/delivery" class="menu-link">
                <i class="menu-icon tf-icons bx bx-bell"></i>
                <div data-i18n="Support"> المندوبين  </div>
              </router-link>
            </li>
            <li class="menu-item" v-if="acount_type == 'admin'">
              <router-link to="/member/muazaf" class="menu-link">
                <i class="menu-icon tf-icons bx bx-bell"></i>
                <div data-i18n="Support"> الموظفين  </div>
              </router-link>
            </li>
            <li class="menu-item" v-if="acount_type == 'admin'">
               <router-link to="/plans" class="menu-link">
                 <i class="menu-icon tf-icons bx bx-user"></i>
                 <div data-i18n="Support"> الباقات الغذائية </div>
               </router-link>
             </li>       

            <li class="menu-item" v-if="acount_type == 'admin'">
              <router-link to="/payment" class="menu-link">
                <i class="menu-icon tf-icons bx bx-bell"></i>
                <div data-i18n="Support"> التحويلات البنكية  </div>
              </router-link>
            </li>
            <li class="menu-item" v-if="acount_type == 'admin' || acount_type == 'clint'">
              <router-link to="/subscription" class="menu-link">
                <i class="menu-icon tf-icons bx bx-bell"></i>
                <div data-i18n="Support"> الاشتركات  </div>
              </router-link>
            </li>
            <li class="menu-item" v-if="acount_type == 'admin'">
              <router-link to="/to.day" class="menu-link">
                <i class="menu-icon tf-icons bx bx-bell"></i>
                <div data-i18n="Support"> طلبات اليوم  </div>
              </router-link>
            </li>

            <li class="menu-item" v-if="acount_type == 'admin'">
              <router-link to="/daily.invoices" class="menu-link">
                <i class="menu-icon tf-icons bx bx-bell"></i>
                <div data-i18n="Support"> الفواتير اليومية </div>
              </router-link>
            </li>

            <li class="menu-item" v-if="acount_type == 'admin'">
              <router-link  to="/service"  class="menu-link">
                <i class="menu-icon tf-icons bx bx-support"></i>
                <div data-i18n="Support">الخدمات</div>
              </router-link >
            </li>
            <li class="menu-item" v-if="acount_type == 'admin'">
              <router-link  to="/city" class="menu-link">
                <i class="menu-icon tf-icons bx bx-support"></i>
                <div data-i18n="Support">المدن</div>
              </router-link >
            </li>
            <li class="menu-item" v-if="acount_type == 'admin'">
              <router-link to="/contact"  class="menu-link">
                <i class="menu-icon tf-icons bx bx-support"></i>
                <div data-i18n="Support">رسائل التواصل</div>
              </router-link>
            </li>
            <li class="menu-item" v-if="acount_type == 'admin'">
              <router-link to="/teams"  class="menu-link">
                <i class="menu-icon tf-icons bx bx-support"></i>
                <div data-i18n="Support">فريق العمل</div>
              </router-link>
            </li>

            <li class="menu-item" v-if="acount_type == 'admin'">
              <router-link to="/delivery.cases"  class="menu-link">
                <i class="menu-icon tf-icons bx bx-support"></i>
                <div data-i18n="Support"> حالات التوصيل </div>
              </router-link>
            </li>
            <li class="menu-item" v-if="acount_type == 'admin'">
              <router-link to="/orders"  class="menu-link">
                <i class="menu-icon tf-icons bx bx-support"></i>
                <div data-i18n="Support"> الطلبات </div>
              </router-link>
            </li>
            <li class="menu-item">
              <a @click="logout()"  class="menu-link">
                <i class="menu-icon tf-icons bx bx-support"></i>
                <div data-i18n="Support">تسجيل الخروج</div>
              </a>
            </li>
  
          <div class="ps__rail-x" style="left: 0px; bottom: 0px;"><div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div></div><div class="ps__rail-y" style="top: 0px; right: 245px;"><div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div></div></ul>
        </aside>


</template>



