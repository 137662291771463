
<template>
  <div class="container">
    <!-- Card Table -->
          

    <div class="card">

      <form @submit.prevent="" @submit="filter()" class=" searchBox">
          <nav class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
            <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
              <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
                <i class="bx bx-menu bx-sm"></i>
              </a>
            </div>
            <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
              <div class="navbar-nav align-items-center">
                <div class="nav-item d-flex align-items-center">
                  <i class="bx bx-search fs-4 lh-0"></i>
                  <input type="text" 
                         v-model="searchBox"
                         class="form-control border-0 shadow-none" 
                         placeholder="Search..." 
                         aria-label="Search..."
                  />
                </div>
              </div>
            </div>

            <input type="date" class="form-control" v-model="filter_start">
            <input type="date" class="form-control" v-model="filter_end">
            <select v-model="filter_status" class="form-select form-control">
                <option value="0">حالة التوصيل</option>
                <option v-for="cases in delivery_cases" :value="cases.id" v-bind:key="cases">{{ cases.name }}</option>
            </select>
            <select v-model="filter_delivery" class="form-select form-control">
                <option value="0">عامل التوصيل</option>
                <option v-for="de in loopDelivery" :value="de.id" v-bind:key="de">{{ de.name }}</option>
            </select>

            <button class="btn btn-dark " type="submit">بحث</button>
          </nav> 
    </form>
        <br>

        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>الاســــــــــم</th>
                <th> العنوان </th>
                <th> الهاتف </th>
                <th> حالة التوصيل </th>
                <th>عامل التوصيل</th>
                <th>سعر التوصيل</th>
                <th> اخر تحديث </th>
                <th  v-if="acount_type == 'admin'">تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.username }}  </td>
                <td> {{ item.address }}  </td>
                <td> {{ item.phone }}  </td>

                <td> {{ item.cases_name }}</td>
                <td> {{ item.deliverey_name }}</td>
                <td> {{ item.delivery_price }}</td>
                <td> {{ item.time_last_update }}</td>

                <td v-if="acount_type == 'admin'">
                  <a  class="btn-action" 
                      @click="onDelete(item.id)"> 
                      <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#updateStatus" 
                      @click="getIdOrder(item.id)"
                      > 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>
            <tfoot class="table-border-bottom-0">

            </tfoot>
          </table>
          <div>اجمالي مستحقات التوصيل : {{ totalOrder }} ريال</div>
        </div>
    </div>
    <!-- Card Table -->


    <!-- Create Row -->
    <div class="modal fade" id="updateStatus" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">

              <input type="hidden" v-model="order_id">

              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name">  حالة التوصيل  </label>
                  <div class="col-sm-10">
                    <select name="" class="form-select form-control" @change="updateStatus">
                        <option>حالة التوصيل</option>
                        <option v-for="cases in delivery_cases" :value="cases.id"  v-bind:key="cases">{{ cases.name }}</option>
                    </select>                  
                  </div>
              </div>                       
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name">  عامل التوصيل  </label>
                  <div class="col-sm-10">
                    <select  name="" class="form-select form-control" @change="updateDelivery">
                      <option> عامل التوصيل</option>
                      <option v-for="de in loopDelivery" :value="de.id"  v-bind:key="de">{{ de.name }}</option>
                    </select>
                  </div>
              </div> 



            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->


  </div> <!-- container -->   
</template>

<style>
.searchBox .form-select {
    background-clip: padding-box;
    width: 200px;
    display: inline-block;
    margin-left: 10px;
}
</style>

<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://milddiets.com/back-end/api";
}
import pagination from 'laravel-vue-pagination'
export default {
  name: "foods-s",
  components:{
            pagination
        },
  data() {
    return {
      // Validation
      formValdatorCreatePlanss : [],
      //statusValidation: "",
      // loop Data
      selectedFile: null,
      selectedFileName: null,
      loopData: [],
      planFoods: [],
      loopCity: [],
      loopDelivery: [],
      LoopClints: [],
      delivery_cases: [],

      order_id: "",
      totalOrder : 0,

      acount_type: localStorage.getItem('acount_type'),
      user_id : localStorage.getItem('user_id'),

      filter_status: "",
      filter_city: "",
      filter_start: "",
      filter_end: "",
      filter_delivery: "",
      filter_clint: "",
      searchBox: "",
      price: null,
    }
  },
  
  mounted () {
    window.document.title = " الطلبات ";
    this.getData();

    console.log(localStorage.getItem('acount_type'));
  }, // mounted

  methods : {

    getIdOrder (id) {
      this.order_id = id
    },



    filter () {
      axios.get(`${urlSite}/orders/${this.acount_type}/${this.user_id}`
      + "?status="    + this.filter_status
      + "&city="   + this.filter_city
      + "&delivery="   + this.filter_delivery
      + "&clint="   + this.filter_clint
      + "&start="  + this.filter_start
      + "&end="    + this.filter_end
      + "&search=" + this.searchBox
      ) 
      .then(res => {
        this.loopData = res.data.orders;
        this.totalOrder = res.data.totalOrder;
        })
    },

    async updateStatus () {
      const formData = {
        id     : this.order_id,
        status : event.target.value,
        column : "delivery_cases",
        table  : "order",
      }
      const response = await axios.post(`${urlSite}/update.status`, formData)
      if(response) {
        this.getData();
        Swal.fire({
          position: 'center', timer: 2000, icon: 'success',
          title: 'تم تحديث البيانات بنجاح',
          showConfirmButton: false,

        });
      }
    },

    
    async updateDelivery () {
      const formData = {
        id     : this.order_id,
        status : event.target.value,
        column : "delivery",
        table  : "order",
      }
      const response = await axios.post(`${urlSite}/update.status`, formData)
      if(response) {
        this.getData();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'تم تحديث البيانات بنجاح',
          showConfirmButton: false,
          timer: 2000
        });
      }
    },


    
    async updateDeliveryPrice () {
      const formData = {
        id     : this.order_id,
        status : this.price,
        column : "delivery_price",
        table  : "order",
      }
      const response = await axios.post(`${urlSite}/update.status`, formData)
      if(response) {
        this.getData();
        Swal.fire({
          position: 'center', timer: 2000, icon: 'success',
          title: 'تم تحديث البيانات بنجاح',
          showConfirmButton: false,

        });
      }
    },

    async getData(page = 1){
      const response = await axios.get(`${urlSite}/orders/${this.acount_type}/${this.user_id}`)
        .then(res => {
          //loopData = await response.json();
          this.loopData = res.data.orders;
          this.delivery_cases = res.data.delivery_cases;
          this.loopCity = res.data.city;
          this.loopDelivery = res.data.delivery;
          this.LoopClints = res.data.clints
          this.totalOrder = res.data.totalOrder
        })
                
    },





 




    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "order", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete





 }
}
</script>
   