import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import Vue from 'vue'

var urlBackEnd = "http://127.0.0.1:8000/";
//import http from '@/types/http'
//interface Vue {
//    $http: http
//  }
  

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App)
const ss = app.config.globalProperties.$hostname = 'http://localhost:8080'
console.log(app.config.globalProperties.$hostname)

app.use(VueSweetalert2);

createApp(App).use(store).use(router).mount("#app");
