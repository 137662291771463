

<template>
    <div>

<body style="background-color: #fff;">

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
      <a class="navbar-brand" href="#">
        <!--
        <img src="../../public/assets/logo-brand.png" alt="">
        -->
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" href="#home"> الرئيسية </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#about">من نحن</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#searvice"> خدماتنا </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#searvice"> الانظمـــة الغذائية </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#team"> فريق اخصائي التغذية </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#contact">الاتصال بنا</a>
          </li>


          <li class="nav-item" v-if="status_auth != 'authorization'">
            <a  class="nav-link" 
            data-toggle="modal" data-target="#editModalLabel" >
                حساب جديد 
            </a>
          </li>
          <li class="nav-item" v-if="status_auth != 'authorization'">
            <a  class="nav-link"
                data-toggle="modal" 
                data-target="#newModal"> 
                تسجيل الدخول 
            </a>
          </li>
          <li class="nav-item" v-if="status_auth == 'authorization'">
            <router-link class="nav-link" to="/dashbord"> لوحة التحكم </router-link>
          </li>
          <li class="nav-item" v-if="status_auth == 'authorization'" @click="Logout()">
            <router-link class="nav-link" to="#logout"> تسجيل الخروج </router-link>
          </li>


        </ul>

      </div>
    </div>
  </nav>








<div class="program">
  <div class="container">
    <div class="row justify-content-center">

    <div class="row justify-content-center title-section">
      <div class="col-md-6 text-center">
        <h2>الانظمـــة الغذائية</h2>
        <h6>مرحبا بكم في عالم مركز التغذية والحمية. سواء كنت ببساطة تريد انقاص الوزن، واتخاذ خيارات غذائية صحية أو تعاني من حالة طبية محددة فنحن واثقون من أن برنامجنا سوف يساعدك في الوصول إلى هدفك وتحقيق الاحتياجات الغذائية الخاصة بك.</h6>
      </div>
    </div>

      <div class="col-md-4" v-for="item in loopPlan" v-bind:key="item">
        <div class="box">
          <div class="content">
            <img :src="`https://milddiets.com/back-end/public/plans/${item.picture}`"  height="50" width="50" alt="">
            <h4> {{ item.name }} </h4>
            <p>{{ item.description }}</p>
            <strong> {{ item.price }} ر س</strong>
            <br><br>
            <button class="btn btn-primary" 
                    @click="planID" 
                    :data-id="item.id"  
                    on-click="alert" 
                    data-toggle="modal" 
                    data-target="#newModal">
              اشتــــراك
            </button>

<!--
            <button v-if="this.status_auth != 'authorization'" 
                    class="btn btn-primary" 
                    @click="planID" 
                    :data-id="item.id"
                    data-toggle="modal" 
                    data-target="#newModal">
              اشتــــراك 
            </button>
            <button v-else class="btn btn-primary" @click="planID" :data-id="item.id"  on-click="alert" data-toggle="modal" data-target="#createSubscription">
              اشتــــراك
            </button>

          -->
          </div>
        </div> <!-- box -->
        <br>
      </div> <!-- col-md-8 -->


    </div>  <!-- row justify-content-center -->
  </div>  <!-- container -->
</div> <!-- program -->




<button type="button" 
                class="btn btn-primary btn-new btn-xs" 
                data-toggle="modal" 
                data-target="#newModal" 
                style="float: left;"
                >اضافة
        </button>





    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">
              <div class="row mb-3">
                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name">الاســــــــــم</label>
                    <input required type="text" class="form-control" id="basic-default-name" v-model="username" />
                  </div>
                  <div class="col-sm-6">
                    <label class="col-form-label" for="basic-default-name">رقم الهاتف</label>
                    <input required type="text" class="form-control" id="basic-default-name" v-model="phone" />
                  </div>
              </div>

              <div class="row mb-3">
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">العمر</label>
                    <input required type="text" class="form-control" id="basic-default-name" v-model="age" placeholder="يرجي ادخال العمر" />
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">الوزن</label>
                    <input required type="text" class="form-control" id="basic-default-name" v-model="weight" placeholder="يرجي ادخال الوزن" />
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">الطول</label>
                    <input required type="text" class="form-control" id="basic-default-name" v-model="height" placeholder="يرجي ادخال الطول" />
                  </div>
              </div>
              
              <div class="row mb-3">
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name"> تاريخ بدء الاشتراك </label>
                    <input required type="date" class="form-control" id="basic-default-name" v-model="date" />
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name"> النشاط الممارس </label>
                    <select v-model="practiced_activity" class="form-control form-select">
                      <option value=""></option>
                      <option value="خامل">خامل</option>
                      <option value="حركة بسيطة">حركة بسيطة</option>
                      <option value="رياضة متوسطة 1-3 ايام في الاسبوع">رياضة متوسطة 1-3 ايام في الاسبوع</option>
                      <option value="رياضة شديدة">رياضة شديدة</option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">مدة الاشتراك</label>
                    <select v-model="days" class="form-control form-select">
                      <option value="1">1 يوم</option>
                      <option value="2">2 يوم</option>
                      <option value="3">3 يوم</option>
                      <option value="4">4 يوم</option>
                      <option value="5">5 يوم</option>
                      <option value="6">6 يوم</option>
                      <option value="7">7 يوم</option>
                      <option value="8">8 يوم</option>
                      <option value="9">9 يوم</option>
                      <option value="10">10 يوم</option>
                      <option value="11">11 يوم</option>
                      <option value="12">12 يوم</option>
                      <option value="13">13 يوم</option>
                      <option value="14">14 يوم</option>
                      <option value="15">15 يوم</option>
                      <option value="16">16 يوم</option>
                      <option value="17">17 يوم</option>
                      <option value="18">18 يوم</option>
                      <option value="19">19 يوم</option>
                      <option value="20">20 يوم</option>
                      <option value="21">21 يوم</option>
                      <option value="22">22 يوم</option>
                      <option value="23">23 يوم</option>
                      <option value="24">24 يوم</option>
                      <option value="25">25 يوم</option>
                      <option value="26">26 يوم</option>
                      <option value="27">27 يوم</option>
                      <option value="28">28 يوم</option>
                      <option value="29">29 يوم</option>
                      <option value="30">30 يوم</option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name"> ايام التوصيل  </label>
                    <select v-model="delivery_days" required class="form-control">
                      <option value="من السبت - الخميس">من السبت - الخميس</option>
                      <option value="من الاحد - الخميس">من الاحد - الخميس</option>
                      <option value="من السبت - الاربع">من السبت - الاربع</option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">الوجبات</label>
                    <select class="form-control" required v-model="meals_count">
                      <option :value="item.id" v-for="item in loopMeals" :key="item"> {{ item.name }}</option>
                    </select>
                  </div>

                  <div class="col-sm-4">
                      <label class="col-form-label" for="basic-default-name"> طريق الاستلام </label>
                      <select class="form-control" required v-model="receiving_method">
                        <option value="استـــــلام من المركز">استـــــلام من المركز</option>
                        <option value="توصيــــــل">توصيــــــل</option>
                      </select>
                    </div>
                  
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name"> بيانات التوصيل </label>
                    <input type="text" class="form-control" required v-model="delivery_data">
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">ملاحظات</label>
                    <textarea cols="2" class="form-control" required rows="2" v-model="note" ></textarea>
                  </div>

                </div> 




              <!-- الاطعمه الغير مرغوب فيها  -->
              <div class="row mb-3">
                <label class="col-sm-12 col-form-label" for="basic-default-name"> الاطعمه الغير مرغوب فيها </label>
                <div class="row">
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="الحليب" v-model="meals" id="checked_1">
                      <label class="form-check-label" for="checked_1">الحليب</label>
                  </div>
                  <div class="form-check form-switch mb-2 col-sm-2">
                      <input class="form-check-input" type="checkbox" value="سمك" v-model="meals" id="checked_2">
                      <label class="form-check-label" for="checked_2">سمك</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="الدجاج" v-model="meals" id="checked_3">
                      <label class="form-check-label" for="checked_3">الدجاج</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="الفراوله" v-model="meals" id="checked_4">
                      <label class="form-check-label" for="checked_4">الفراوله</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="الصويا" v-model="meals" id="checked_5">
                      <label class="form-check-label" for="checked_5">الصويا</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="البقول" v-model="meals" id="checked_6">
                      <label class="form-check-label" for="checked_6">البقول</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="اللحم" v-model="meals" id="checked_7">
                      <label class="form-check-label" for="checked_7">اللحم</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-3">
                      <input class="form-check-input" type="checkbox" value="الفول السوداني" v-model="meals" id="checked_8">
                      <label class="form-check-label" for="checked_8">الفول السوداني</label>
                  </div>                  
                  <div class="form-check form-switch mb-2  col-sm-3">
                      <input class="form-check-input" type="checkbox" value="الباذنجان" v-model="meals" id="checked_9">
                      <label class="form-check-label" for="checked_9">الباذنجان</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="الكوسا" v-model="meals" id="checked_10">
                      <label class="form-check-label" for="checked_10">الكوسا</label>
                  </div>
                  <!--
                  <div class="form-check form-switch mb-2  col-sm-3">
                      <input class="form-check-input" type="checkbox" value="الباذنجان" v-model="meals" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الباذنجان</label>
                  </div>
                  -->
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="البيض" v-model="meals" id="checked_11">
                      <label class="form-check-label" for="checked_11">البيض</label>
                  </div>


                </div>
              </div>
              <!-- الاطعمه الغير مرغوب فيها  -->


              <li class="text-center text-danger" v-for="error in formValdatorCreate" v-bind:key="error"> {{ error  }} <br></li>


            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



 











 
 </body>
        
    </div>
</template>

<style scoped >



.program {
  background: #f4f4f4ba;
  padding: 100px 0px;
  text-align: center;
  margin-bottom: 100px;
}

.program .box {
  padding: 20px;
  background-color: #fff;
  text-align: center;

}
.program .box .content {
  padding: 20px;
  border: 1px solid #ccc;
}


.program .box img {
  width: 200px;
  height: 200px;
  display: block;
  margin: auto;
  margin-bottom: 35px;
  border-radius: 100%;
}



</style>




<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://milddiets.com/back-end/api";
}
//import { defineComponent } from '@vue/composition-api'

export default {
  name: "plan",
  data() {
      return {
          loopPlan: [],
          meals : [],
          status: "",
          plan : "",
          days : "",
          meals_count: "",
          date : "",
          note : "",
          username : "",
          phone : "",
          age : "",
          weight : "",
          height : "",
          practiced_activity : "",
          delivery_days : "",
          delivery_data : "",
          receiving_method : "",
      }
  }, // return data



  mounted () {
      window.document.title = "الباقات";
      this.getData();
  }, // mounted

   methods : {

    Logout() {
      localStorage.removeItem("status_auth");
      localStorage.removeItem("user_id");
      window.location.replace("/");
    },

    
    planID(event) {
      this.plan_id = event.target.getAttribute('data-id');
    },

    async getData() {
          const response = await axios.get(`${urlSite}/public.category/${this.$route.params.category}`);
          if (response) {
            this.loopPlan = response.data.data;
            this.loopMeals =  response.data.meals
          }
    }, // getData


    async store  () {
        const response = await axios.post(`${urlSite}/store.subscription`, {
            plan     : this.plan_id,
            status   : 1,
            days     : this.days,
            meals_count : this.meals_count,
            date     : this.date,
            note     : this.note,
            username : this.username,
		        phone    : this.phone,
		        age      : this.age,
	   	      weight   : this.weight,
	  	      height   : this.height,
	  	      practiced_activity : this.practiced_activity,
	  	      delivery_days : this.delivery_days,
	  	      days : this.days,
	  	      meals : this.meals,
            delivery_data : this.delivery_data,
            receiving_method : this.receiving_method,
          });
          if(response) {
          this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      } // response

      

    }, // Store Function



  }, // methods








}

</script>


