
<template>
  <div class="container">
    <!-- Card Table -->
    <div class="card">
        <button type="button" 
                class="btn btn-primary btn-new btn-xs" 
                data-toggle="modal" 
                data-target="#newModal" 
                style="float: left;"
                >اضافة
        </button>
        <div class="table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>الاســــــــــم</th>
                <th> السعر </th>
                <th> الوصف </th>
                <th> صورة </th>
                <th> الاصناف </th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.name">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.name }}  </td>
                <td> {{ item.price }}  </td>
                <td style="width: 300px;"> {{ item.description }}  </td>
                <td> <img :src="`https://milddiets.com/back-end/public/plans/${item.picture}`" class="d-block rounded" height="50" width="50" alt="">  </td>

                <td>   
                  <span v-for="plan_food in loopPlanFood" v-bind:key="plan_food"> 
                    <span class="badge bg-label-primary" v-if="item.id == plan_food.plan">  {{ plan_food.name }} </span>
                  </span>
                </td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.name, item.price, item.description)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>
            <tfoot class="table-border-bottom-0">

            </tfoot>
          </table>
        </div>
    </div>
    <!-- Card Table -->



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">


              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> اسم الخطة </label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="basic-default-name" v-model="name" />
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> السعر  </label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="basic-default-name" v-model="price" />
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> الوصف  </label>
                  <div class="col-sm-10">
                    <textarea name="" id="" cols="3"  v-on:change="selectedFiles" class="form-control" rows="3" v-model="description" ></textarea>
                  </div>
              </div> 

              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> الصورة  </label>
                  <div class="col-sm-10">
                    <img  v-if="uploadFilePicture.imageUrl" 
                          :src="uploadFilePicture.imageUrl" 
                          class="d-block rounded" 
                          height="100" 
                          width="100"
                    />
                    <input type="file" class="form-control" id="basic-default-name" @change="uploadPicture" />
                  </div>
              </div>

              
              <div>
                <div class="row">
                  <div class="col-md-4" v-for="item in loopFood">
                    <div class="form-check form-switch mb-2">
                        <input  class="form-check-input" type="checkbox" :value="item.id" v-model="arrayFood"  id="flexSwitchCheckChecked_1">
                        <label class="form-check-label" for="flexSwitchCheckChecked_1">{{ item.name }}</label>
                    </div>
                  </div>
                </div>
              </div>
              

              <li v-for="error in formValdatorCreatePlanss"> {{ error  }} </li>


            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Create Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel">تحديث البيانات</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">
              <input type="hidden"  v-model="editID" >


              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> اسم الخطة </label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="basic-default-name" v-model="editName" />
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> السعر  </label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="basic-default-name" v-model="editPrice" />
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> الوصف  </label>
                  <div class="col-sm-10">
                    <textarea  class="form-control" rows="3" v-model="editDescription" ></textarea>
                  </div>
              </div> 

              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> الصورة  </label>
                  <div class="col-sm-10">
                    <img  v-if="uploadFilePicture.imageUrl" 
                          :src="uploadFilePicture.imageUrl" 
                          class="d-block rounded" 
                          height="100" 
                          width="100"
                    />
                    <input type="file" class="form-control" id="basic-default-name" @change="uploadPicture" />
                  </div>
              </div>
              
              <div>
                <div class="row">
                  <div class="col-md-4" v-for="item in loopFood" v-bind:key="item.id">
                    <div class="form-check form-switch mb-2">
                        <input  class="form-check-input" 
                                type="checkbox" 
                                :value="item.id" 
                                :v-model="arrayFood"  
                                id="flexSwitchCheckChecked_1"
                        />
                        <label class="form-check-label" for="flexSwitchCheckChecked_1">{{ item.name }}</label>
                    </div>
                  </div>
                </div>
              </div>
              

            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->

  </div> <!-- container -->   
</template>


<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://milddiets.com/back-end/api";
}
import PlansValidation from '../validation/PlansValidation.js'
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      formValdatorCreatePlanss : [],
      //statusValidation: "",
      // loop Data
      selectedFile: null,
      selectedFileName: null,
      loopData: [],
      loopFood: [],
      arrayFood: [],
      loopPlanFood: [],
      loopCategory: [],
      // Create Row
      name: "",
      price: "",
      description: "",
      picture: "",
      image: "",
      // Edit Row
      editID: "",
      editName: "",
      editPrice: "",
      editDescription: "",

      uploadFilePicture : {
        picture: null,
        imageUrl: null,
        getPicture: null
      },       
    }
  },

  mounted () {
      window.document.title = " الباقات الغذائية ";
      if (!localStorage.getItem("status_auth")) {
          window.location.href = '/login';
      }
      this.getData();
  }, // mounted

  methods : {

    uploadPicture(e){
      this.uploadFilePicture.picture = e.target.files[0];
      this.uploadFilePicture.imageUrl = URL.createObjectURL(e.target.files[0])
      this.uploadFilePicture.getPicture = e.target.files[0].name;
    },

    getData(){
      axios.get(`${urlSite}/plans`)
        .then(res => {
          this.loopData = res.data.plans
          this.loopFood = res.data.foods
          this.loopPlanFood = res.data.planFood
          this.loopCategory = res.data.categories
        })
    
    },

    store  () {
      const formData = new FormData();
      formData.append("category", this.$route.params.id);
      formData.append("name", this.name);
      formData.append("price", this.price);
      formData.append("description", this.description);
      formData.append("arrayFood", this.arrayFood);
      formData.append("picture", this.uploadFilePicture.picture);

      axios.post(`${urlSite}/store.plan`, formData)
      .then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      });

    },


    // Get Data For Edit
    openEditModel (getID, getName, getPrice, getDescription) {
      this.editID      = getID
      this.editName    = getName
      this.editPrice   = getPrice
      this.editDescription  = getDescription
    }, // openEditModel


    async update () {
      const formData = new FormData();
      formData.append("id", this.editID);
      formData.append("name", this.editName);
      formData.append("price", this.editPrice);
      formData.append("description", this.editDescription);
      formData.append("arrayFood", this.arrayFood);
      formData.append("picture", this.uploadFilePicture.picture);
      const response = await axios.post(`${urlSite}/update.plan`, formData)
      if(response) {
        this.getData();
        this.uploadFilePicture.picture = null;
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      }

    },



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "plans", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete







 }
}
</script>
   