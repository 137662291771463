

<template>
    <div>

<body style="background-color: #fff;">

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
      <a class="navbar-brand" href="#">
        <!--
        <img src="../../public/assets/logo-brand.png" alt="">
        -->
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" href="#home"> الرئيسية </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#about">من نحن</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#searvice"> خدماتنا </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#searvice"> الانظمـــة الغذائية </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#team"> فريق اخصائي التغذية </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#contact">الاتصال بنا</a>
          </li>


          <li class="nav-item" v-if="status_auth != 'authorization'">
            <a  class="nav-link" 
            data-toggle="modal" data-target="#editModalLabel" >
                حساب جديد 
            </a>
          </li>
          <li class="nav-item" v-if="status_auth != 'authorization'">
            <a  class="nav-link"
                data-toggle="modal" 
                data-target="#newModal"> 
                تسجيل الدخول 
            </a>
          </li>
          <li class="nav-item" v-if="status_auth == 'authorization'">
            <router-link class="nav-link" to="/dashbord"> لوحة التحكم </router-link>
          </li>
          <li class="nav-item" v-if="status_auth == 'authorization'" @click="Logout()">
            <router-link class="nav-link" to="#logout"> تسجيل الخروج </router-link>
          </li>


        </ul>

      </div>
    </div>
  </nav>

  <div class="header" id="home" style="background: url(layout/img/restaurant-slide1.jpg);">
    <div class="container">
      <div class="row">
        <div class="col-12 col-12 col-md-12 col-lg-3 col-xl-5">

        </div>
        <div class="col-12 col-12 col-md-12 col-lg-10 col-xl-7">
          <h2> تناول طعاماً صحياً لتتمع بصحة جيدة وتكن سعيــــــــداً </h2>
          <p>سلامة وجودة الأغذية هي اولويتنا أطعمة مغذية ولذيذة … نعدكم بتقديم أطعمة ذات جودة عالية ومذاق رائع.</p>
          <br>
          <div>
            <a class="btn" href="#about">تعرف علينا</a>
            <a class="btn btn-transparent" href="#contact"> الاتصال بنا  </a>
          </div>
        </div>
      </div>
    </div>
  </div>




  




  <div class="about-home" id="about">
    <div class="container">


      <div class="row justify-content-center title-section">
        <div class="col-md-8 text-center">
          <h2> من نحــــــــن </h2>
          <h6>حن فريق ميلد دايت نشأت فكرتنا من تقديم برامج غذائية متنوعه اون لاين منذ عام ٢٠١٦ شملت مجموعه كبيره من الحالات الصحية المختلفة كـ انزال الوزن، تثبيت الوزن، زيادة الوزن، رعاية كبار السن، رعاية مرضى الامراض المزمنه، برامج الاطفال، النباتيين ، و لو كارب. ثم تطورت الفكرة الى توفير وجبات صحية ومتنوعة وقامت الفكرة على انشاء مطبخ حسابي لهذه الوجبات الصحيه منذ ٢٠٢٠ تلبية لطلبات عملائنا الكرام في توفير وجبات صحيه وعضويه ب اشتراكات شهريه او ويومية او طلبات الجمعات والمناسبات تهدف الى تحسين صحة العميل بشكل عام. وجباتنا تشمل رعاية مختلف الحالات بدءا من تحسين الصحة و الحفاظ على الوزن، انزال الوزن، زيادة الوزن، رعاية مرضى القولون والمرارة، وكبار السن ومرضي السرطان و مرضي السكري والضغط و الكوليسترول، والنباتيين، الى الكيتو وغيرها الكثير.</h6>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-6">
          <div class="image">
            <img width="100%" src="https://demo.casethemes.net/organio/wp-content/uploads/2021/03/about-img2.jpg" alt="">
          </div>
       
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6">
          <div class="content">
            <img class="about-logo" src="layout/img/logo.png" alt=""> 
            <h4 class="title"> الأهـــــــــداف</h4>
            <p>هدف المشروع ان تصلكم وجباتكم المتنوعه والمتجدده يوميا محافظا على جودة الطعام طازجا وشهيا ونظيفا. شعارنا هو توفير الوقت و الجهد ورعاية المجتمع بنوفير وجبات صحية وعضويه و لذيذه و متنوعه تلبي رغباته. كما نسعى دوما للتطور والتقدم.</p>
            <br>

            <div class="row">
              <div class="col-md-6">
                <div class="box">
                  <img src="https://demo.casethemes.net/organio/wp-content/uploads/2021/03/organic-food2.png" alt="">
                  <h6>مهمتنا</h6>
                  <p>تجاوز مفهوم فقدان الوزن بشكل خاص وتحسين الصحة بشكل عام إلى تحقيق حالة صحية أفضل. نحن نقدم استشارات غذائية لجميع الحالات ونقدم أطباق شهية بأعلى جودة.</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="box">
                  <img src="https://demo.casethemes.net/organio/wp-content/uploads/2021/03/organic-food2.png" alt="">
                  <h6>الرؤية</h6>
                  <p>الوعي هو الدافع الرئيسي الذي يؤثر على سلوك الناس، وجهودنا المستمرة لتزويد المجتمع في قطر بأغذية عالية الجودة ومغذية تناسب الجميع لحياة اكثر صحة</p>
                </div>
              </div>
            </div>

            <a href=""> تواصل معنا</a>  <!-- او <a href="" style="background-color: #51f2b8; margin-right: 20px;"> زيارة منتجاتنا </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>








<div class="service">
  <div class="container">

    <div class="row justify-content-center  title-section">
      <div class="col-md-6 text-center">
        <h2>خدماتنا</h2>
        <h6>مرحبا بكم في عالم مركز التغذية والحمية. سواء كنت ببساطة تريد انقاص الوزن، واتخاذ خيارات غذائية صحية أو تعاني من حالة طبية محددة فنحن واثقون من أن برنامجنا سوف يساعدك في الوصول إلى هدفك وتحقيق الاحتياجات الغذائية الخاصة بك.</h6>
      </div>
    </div>


    <div class="row justify-content-center">

      <div class="col-md-4" v-for="item in loopService" v-bind:key="item">
        <div class="box">
          <img :src="`../img/${item.picture}`"  height="50" width="50" alt="">
          <div class="content">
            <h5> {{ item.name }} </h5>
            <p>{{ item.description }}</p>
          </div>
        </div> <!-- box -->
      </div> <!-- col-md-8 -->


    </div>  <!-- row justify-content-center -->
  </div>  <!-- container -->
</div> <!-- program -->








<div class="program">
  <div class="container">
    <div class="row justify-content-center">

    <div class="row justify-content-center title-section">
      <div class="col-md-6 text-center">
        <h2>الانظمـــة الغذائية</h2>
        <h6>مرحبا بكم في عالم مركز التغذية والحمية. سواء كنت ببساطة تريد انقاص الوزن، واتخاذ خيارات غذائية صحية أو تعاني من حالة طبية محددة فنحن واثقون من أن برنامجنا سوف يساعدك في الوصول إلى هدفك وتحقيق الاحتياجات الغذائية الخاصة بك.</h6>
      </div>
    </div>

      <div class="col-md-4" v-for="item in loopPlan" v-bind:key="item">
        <div class="box">
          <div class="content">
            <img :src="`https://milddiets.com/back-end/public/plans/${item.picture}`"  height="50" width="50" alt="">
            <h4> {{ item.name }} </h4>
            <p>{{ item.description }}</p>
            <strong> {{ item.price }} ر س</strong>
            <br><br>

            <router-link :to="'/plan/'+item.id" class="btn btn-primary">
              زيارة الباقات
            </router-link>
          <!--
            <button v-if="this.status_auth != 'authorization'" 
                    class="btn btn-primary" 
                    @click="planID" 
                    :data-id="item.id"
                    data-toggle="modal" 
                    data-target="#newModal">
              اشتــــراك
            </button>
            <button v-else class="btn btn-primary" @click="planID" :data-id="item.id"  on-click="alert" data-toggle="modal" data-target="#createSubscription">
              اشتــــراك
            </button>
          -->
          </div>
        </div> <!-- box -->
        <br>
      </div> <!-- col-md-8 -->


    </div>  <!-- row justify-content-center -->
  </div>  <!-- container -->
</div> <!-- program -->






<div class="team">
  <div class="container">

    <div class="row justify-content-center title-section">
      <div class="col-md-6 text-center">
        <h2> فريق اخصائي التغذية </h2>
        <h6> احصل على أفضل النصائح والمعلومات المتعلقة بالتغذية من منصة خبراء التغذية. لكي تصل لهدفك، تابع أول بأول مع أخصائي التغذية واحصل على النتائح المطلوبة. </h6>
      </div>
    </div>

    <div class="row justify-content-center">

      <div class="col-md-3" v-for="item in loopTeam" v-bind:key="item.id">
        <div class="box">
            <img :src="`./img/plans/${item.picture}`" alt="">
            <div class="content">
              <span>{{ item.work }}</span>
              <h4> {{ item.name }} </h4>
            </div>
        </div> <!-- box -->
      </div> <!-- col-md-8 -->

    </div>  <!-- row justify-content-center -->
  </div>  <!-- container -->
</div> <!-- program -->











 




<div class="consulting" id="contact" style="background: url(https://themeholy.com/wordpress/pizzan/wp-content/uploads/2023/06/booking_bg_2.png);">
  <div class="overlay">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-5"></div>
        <div class="col-md-7">
          <form @submit.prevent="" @submit="store()" class="form row">
            <div class="col-md-6">
              <input type="text" v-model="first_name" placeholder="الاســــــم الاول">
            </div>
            <div class="col-md-6">
              <input type="text" v-model="last_name" placeholder="الاســــــم الثاني">
            </div>
            <div class="col-md-12">
              <input type="text" v-model="email"  placeholder="البريد الالكتروني">
            </div>
            <div class="col-md-12">
              <input type="text" v-model="phone" placeholder="رقم الهاتف">
            </div>
            <div class="col-md-12">
              <textarea  v-model="message" placeholder="رسالتــــــــك" id="" cols="30" rows="7"></textarea>
            </div>
            <div class="col-md-6">
              <input type="submit" class="btn" value="ارســـــال">
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>









    <!-- Create Row -->
    <div class="modal fade authenticator" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">

        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          
          <div class="modal-body">
            <div class="app-brand justify-content-center">
              <a href="index.html" class="app-brand-link gap-2">
                <!--
<img src="../../public/assets/logo-brand.png" alt="">
                -->
                
              </a>
            </div>
            <br>
            <h4 class="mb-2 text-center">تسجيل الدخول! 👋</h4>
            <p class="mb-4  text-center">يرجى تسجيل الدخول إلى حسابك وبدء المغامرة</p>
            <form id="formAuthentication" @submit.prevent="" @submit="Login()" method="POST">
                <div class="mb-3">
                  <label for="email" class="form-label">البريد الالكتروني | الهاتف</label>
                  <input type="text" 
                         class="form-control" 
                         id="email" 
                         v-model="email" 
                         placeholder="Enter Your E-mail" 
                         autofocus="" 
                  />
                </div>
                <div class="mb-3 form-password-toggle">
                  <div class="d-flex justify-content-between">
                    <label class="form-label" for="password">كلمة المرور</label>
                    <a href="auth-forgot-password-basic.html">
                      <router-link to="/ForgotPassword"> نسيت كلمة المرور ?</router-link>
                    </a>
                  </div>
                  <div class="input-group input-group-merge">
                    <input  type="password" 
                            id="password" 
                            class="form-control" 
                            v-model="password" 
                            placeholder="············" 
                            aria-describedby="password"
                    />
                  </div>
                </div>
                <div class="mb-3">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="remember-me">
                    <label class="form-check-label" for="remember-me"> تذكرنــــي </label>
                  </div>
                </div>

                <div class="text-center text-danger"
                    data-toggle="modal" data-target="#editModalLabel"
                     style="font-weight: 700; color: #3c63c5;"
                     v-if="this.statusAuth == true">
                     البيانات غير صحيحة اذا كنت لا تمتلك حساب يرجي <a href="#"> انشاء حساب </a> جديد
                     <br><br>
                </div>

                <div class="text-center text-danger" v-for="error in formValdatorLogin" v-bind:key="error">{{ error }} <br><br></div>
                
                <div class="mb-3">
                  <button class="btn btn-primary d-grid w-100" type="submit">دخـــول</button>
                  <br>
                  <p class="text-center" data-toggle="modal" data-target="#editModalLabel" >
                    <span> لا املك حساب </span>
                      <strong style="cursor: pointer;" class=""> انشاء حساب </strong>
                  </p>
                </div>
              </form>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->





    <!-- Create Row -->
    <div class="modal fade authenticator" id="createSubscription" tabindex="-1" role="dialog" aria-labelledby="createSubscription" aria-hidden="true">
      <div class="modal-dialog" role="document">


        <form @submit.prevent="" @submit="createSubscription()" method="POST" class="modal-content"  enctype="multipart/form-data">
          
          <div class="modal-body">
            <h5 class="modal-title text-center" > اشتــــراك في الخطة</h5>

            <br>
            <form id="formAuthentication" @submit.prevent="" @submit="createSubscription()" method="POST">
                <input type="hidden" v-model="plan_id" />
                <div class="mb-3">
                  <label for="email" class="form-label"> عدد الايام </label>
                  <input type="text" 
                         class="form-control" 
                         id="email" 
                         v-model="days" 
                         placeholder="عدد الايام " 
                         autofocus="" 
                  />
                </div>
                <br>


                      
              <!-- الايــــام  -->
              <div class="row mb-3">
                <label class="col-sm-2 col-form-label" for="basic-default-name"> الايام </label>
                <div class="col-sm-10 row">
                  <div class="form-check form-switch mb-2 col-sm-6">
                      <input class="form-check-input" type="checkbox" value="true" v-model="sunday" id="day_1">
                      <label class="form-check-label" for="day_1">الاحد</label>
                  </div>
                  <br>
                  <div class="form-check form-switch mb-2  col-sm-6">
                      <input class="form-check-input" type="checkbox" value="true" v-model="monday" id="day_2">
                      <label class="form-check-label" for="day_2">الاثنين</label>
                  </div>
                  <div class="form-check form-switch mb-2 col-sm-6">
                      <input class="form-check-input" type="checkbox" value="true" v-model="tuesday" id="day_3">
                      <label class="form-check-label" for="day_3">الثلاثاء</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-6">
                      <input class="form-check-input" type="checkbox" value="true" v-model="wednesday" id="day_4">
                      <label class="form-check-label" for="day_4">الاربع</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-6">
                      <input class="form-check-input" type="checkbox" value="true" v-model="thursday" id="day_5">
                      <label class="form-check-label" for="day_5">الخميس</label>
                  </div>
                  <div class="form-check form-switch mb-2 col-sm-6">
                      <input class="form-check-input" type="checkbox" value="true" v-model="friday" id="day_6">
                      <label class="form-check-label" for="day_6">الجمعه</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-6">
                      <input class="form-check-input" type="checkbox" id="day_7"  v-model="saturday" value="true">
                      <label class="form-check-label" for="day_7">السبت</label>
                  </div>
                </div>
              </div>
              <!-- الايــــام  -->
              <br>
              <!-- الوجبـــــات  -->
              <div class="row mb-3">
                <label class="col-sm-2 col-form-label" for="basic-default-name"> الوجابات </label>
                <div class="col-sm-10 row">
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="breakfast" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الفطـور</label>
                  </div>
                  <div class="form-check form-switch mb-2 col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="lunch" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الغـذاء</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="dinner" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">العشـــــاء</label>
                  </div>
                </div>
              </div>
              <!-- الوجبـــــات  -->
              <br>



                <div v-for="error in formValdatorLogin" v-bind:key="error">{{ error }}</div>

                <div class="mb-3">
                  <button class="btn btn-primary d-grid w-100" type="submit">اشتــــراك</button>
                </div>
              </form>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->




    <!-- Create Row -->
    <div class="modal fade authenticator" id="editModalLabel" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">

        <div @submit.prevent="" @submit="signUp()" method="POST" class="modal-content"  enctype="multipart/form-data">
          
          <div class="modal-body">
            <div class="app-brand justify-content-center">
              <a href="index.html" class="app-brand-link gap-2">
                <!--
                <img src="../../public/assets/logo-brand.png" alt="">
                -->
              </a>
            </div>
            <br>
            <h4 class="mb-2 text-center"> حساب جديد! 👋</h4>
            <p class="mb-4  text-center">يرجى تسجيل الدخول إلى حسابك وبدء المغامرة</p>
            <form id="formAuthentication"  method="POST">

              
              <div class="mb-3">
                  <label for="username" class="form-label">اسم المستخدم</label>
                  <input type="text" class="form-control" id="username" v-model="name" placeholder="Enter your username" autofocus="">
                </div>
                <div class="mb-3">
                  <label for="email" class="form-label">البريد الالكتروني</label>
                  <input type="text" class="form-control" id="email" v-model="email" placeholder="Enter your email">
                </div>
                <div class="mb-3">
                  <label for="phone" class="form-label"> رقم الهاتف </label>
                  <input type="text" class="form-control" id="phone" v-model="phone" placeholder="Enter your Phone">
                </div>
                <div class="mb-3">
                  <label for="email" class="form-label"> المنطقة  </label>
                  <select v-model="city" id="" class="form-control form-select">
                    <option value=""></option>
                    <option v-for="item in loopCity" :value="item.id" v-bind:key="item">{{ item.name }}</option>
                  </select>
                </div>
                <div class="mb-3 form-password-toggle">
                  <label class="form-label" for="password">كلمة المرور</label>
                  <div class="input-group input-group-merge">
                    <input type="password" id="password" class="form-control" v-model="password" placeholder="············" aria-describedby="password">
                  </div>
                </div>
                

                <div v-for="error in formValdatorLogin" v-bind:key="error">{{ error }}</div>

                <div class="mb-3">
                  <button class="btn btn-primary d-grid w-100" type="submit">دخـــول</button>
                  <br>
                  <p class="text-center" data-toggle="modal" data-target="#editModalLabel" >
                    <span> لا املك حساب </span>
                      <a class="">  تسجيل الدخول </a>
                      
                  </p>
                </div>
              </form>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
          </div>

        </div>
      </div>
    </div>
    <!-- Create Row -->




<!--
<div class="testmonus" id="testmonus">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-4 col-lg-6">
        <h3 class="title"> ماذا قالــــــــــوا عنـــا</h3>
      </div>
      <div class="col-12 col-sm-12 col-md-8 col-lg-6">

        <div class="owl-carousel owl-theme owl-rtl owl-loaded owl-drag">
          
          
          
        <div class="owl-stage-outer"><div class="owl-stage" style="transform: translate3d(1210px, 0px, 0px); transition: all 0.25s ease 0s; width: 4235px;"><div class="owl-item cloned" style="width: 595px; margin-left: 10px;"><div class="item">
            <h4> خدمات قانونية</h4>
            <p>وبعبارة أخرى يقصد بالاستشارة القانونية الرأي القانوني الذي يقدمه المحامي المختص أو المستشار القانوني للعميل، وتكون بالاستناد إلى الأنظمة والقوانين المتبعة في المملكة العربية السعودية.</p>
            <strong>Mohamed Abdelrahman </strong> - <span>Bank manager </span>
          </div></div><div class="owl-item cloned" style="width: 595px; margin-left: 10px;"><div class="item">
            <h4> خدمات قانونية</h4>
            <p>وبعبارة أخرى يقصد بالاستشارة القانونية الرأي القانوني الذي يقدمه المحامي المختص أو المستشار القانوني للعميل، وتكون بالاستناد إلى الأنظمة والقوانين المتبعة في المملكة العربية السعودية.</p>
            <strong>Mohamed Abdelrahman </strong> - <span>Bank manager </span>
          </div></div><div class="owl-item active" style="width: 595px; margin-left: 10px;"><div class="item">
            <h4> خدمات قانونية</h4>
            <p>وبعبارة أخرى يقصد بالاستشارة القانونية الرأي القانوني الذي يقدمه المحامي المختص أو المستشار القانوني للعميل، وتكون بالاستناد إلى الأنظمة والقوانين المتبعة في المملكة العربية السعودية.</p>
            <strong>Mohamed Abdelrahman </strong> - <span>Bank manager </span>
          </div></div><div class="owl-item" style="width: 595px; margin-left: 10px;"><div class="item">
            <h4> خدمات قانونية</h4>
            <p>وبعبارة أخرى يقصد بالاستشارة القانونية الرأي القانوني الذي يقدمه المحامي المختص أو المستشار القانوني للعميل، وتكون بالاستناد إلى الأنظمة والقوانين المتبعة في المملكة العربية السعودية.</p>
            <strong>Mohamed Abdelrahman </strong> - <span>Bank manager </span>
          </div></div><div class="owl-item" style="width: 595px; margin-left: 10px;"><div class="item">
            <h4> خدمات قانونية</h4>
            <p>وبعبارة أخرى يقصد بالاستشارة القانونية الرأي القانوني الذي يقدمه المحامي المختص أو المستشار القانوني للعميل، وتكون بالاستناد إلى الأنظمة والقوانين المتبعة في المملكة العربية السعودية.</p>
            <strong>Mohamed Abdelrahman </strong> - <span>Bank manager </span>
          </div></div><div class="owl-item cloned" style="width: 595px; margin-left: 10px;"><div class="item">
            <h4> خدمات قانونية</h4>
            <p>وبعبارة أخرى يقصد بالاستشارة القانونية الرأي القانوني الذي يقدمه المحامي المختص أو المستشار القانوني للعميل، وتكون بالاستناد إلى الأنظمة والقوانين المتبعة في المملكة العربية السعودية.</p>
            <strong>Mohamed Abdelrahman </strong> - <span>Bank manager </span>
          </div></div><div class="owl-item cloned" style="width: 595px; margin-left: 10px;"><div class="item">
            <h4> خدمات قانونية</h4>
            <p>وبعبارة أخرى يقصد بالاستشارة القانونية الرأي القانوني الذي يقدمه المحامي المختص أو المستشار القانوني للعميل، وتكون بالاستناد إلى الأنظمة والقوانين المتبعة في المملكة العربية السعودية.</p>
            <strong>Mohamed Abdelrahman </strong> - <span>Bank manager </span>
          </div></div></div></div><div class="owl-nav"><button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button></div><div class="owl-dots"><button role="button" class="owl-dot active"><span></span></button><button role="button" class="owl-dot"><span></span></button><button role="button" class="owl-dot"><span></span></button></div></div>

      </div>
      </div>
    </div>
  </div>

-->














  <div class="footer">
    <div class="container">
      <div class="row justify-content-center">

        <div class="col-md-5">
          <!--
          <img src="../../public/assets/logo-brand.png" alt="">
          -->
        </div>

        <div class="col-md-5">

          <div>
            <i class="far fa-map-marker-alt"></i>
            <strong>العنوان</strong>
            <p><a href="">برج الغيداء - مكتب ٣٠٨ شارع سماحه النفس، 
              ص٫ب ٨٩٢٨ - جدة ٢٣٤٢٣ 
              المملكة العربية السعودية</a></p>
          </div>
          <div>
            <i class="far fa-map-marker-alt"></i>
            <strong>البريد الالكتروني</strong>
            <p><a href="mailto:info@caesar-sa.com">info@caesar-sa.com</a></p>
          </div>
          <div>
            <i class="far fa-map-marker-alt"></i>
            <strong>رقم الهاتف</strong>
            <p><a href="tel:0561777409">٠٥٦١٧٧٧٤٠٩</a></p>
          </div>
          <div class="media">
              <a target="_blank" href="https://www.facebook.com/Sell2day-109732014765720"><i class="fab fa-facebook-f"></i></a>
              <a target="_blank" href="www.google.com"><i class="fab fa-google-plus-g"></i></a>
              <a target="_blank" href="http://linkedin.com/"><i class="fab fa-linkedin-in"></i></a>
              <a target="_blank" href="https://twitter.com/selltowday"><i class="fab fa-twitter"></i></a>
              <a target="_blank" href="https://www.instagram.com/sell.2day/"><i class="fab fa-instagram"></i></a>
              <a target="_blank" href="https://accounts.snapchat.com"><i class="fab fa-snapchat"></i></a>                                 
              <a target="_blank" href="https://iwtsp.com/966505580553"><i class="fab fa-whatsapp"></i></a>                   
          </div>
        </div>
      </div>
    </div>
      <div class="copyright">
        جميع الحقوق محفوظة لشركة انسبيكتور ويب للبرمجيات
      </div>
  </div>
 


 
 </body>
        
    </div>
</template>




<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://milddiets.com/back-end/api";
}
//import { defineComponent } from '@vue/composition-api'

export default {
  name: "home",
  data() {
      return {
          loopPlan: [],
          loopTeam: [],
          loopService: [],
          loopCity: [],
          formValdatorLogin:[],
          statusAuth: "",
          status_auth : localStorage.getItem("status_auth"),
          plan_id: "",
          // Create Row
          clint : "",
          plan : "",
          days : "",
          //gram : "",
          //note : "",
          // The Days
          sunday : "",
          monday : "",
          tuesday : "",
          wednesday : "",
          thursday : "",
          friday : "",
          saturday : "",
          // The meals
          breakfast : "",
          lunch : "",
          dinner : "",


          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          message: "",

          // Create Acount
          name: "",
          email: "",
          phone: "",
          city: "",
          password: "",
      }
  }, // return data



  mounted () {
      window.document.title = " الصفحة الرئسية  ";
            this.getData();
  }, // mounted

   methods : {

    Logout() {
      localStorage.removeItem("status_auth");
      localStorage.removeItem("user_id");
      window.location.replace("/");

    },

    
    planID(event) {
      this.plan_id = event.target.getAttribute('data-id');
    },
      async getData() {
          const response = await axios.get(`${urlSite}/public`);
          if (response) {
            this.loopPlan = response.data.plan;
            this.loopTeam = response.data.team;
            this.loopService = response.data.service;
            this.loopCity = response.data.city;
          }
      }, // getData


      store  () {
        const formData = new FormData();
        formData.append("name", this.first_name + " " + this.last_name);
        formData.append("email", this.email);
        formData.append("phone", this.phone);
        formData.append("message", this.message);
        axios.post(`${urlSite}/store.contact`, formData)
        .then(res => {
          this.getData();
            Swal.fire({
              icon: 'success',
              title: 'تم ارسال رسالتك بنجاح',
              showConfirmButton: false,
              timer: 3000
            })
        });

    },  // store




    async signUp  () {
      // Valdation
      this.formValdatorCreatePlans = [];
      if(!this.name || !this.email || !this.phone || !this.password) {
        this.formValdatorCreatePlans.push("يرجي ملئ الحقول المطلوبة");
        this.statusValidation = false
      } else {
        this.statusValidation = true;
      } // End Condation Valdator

      if(this.statusValidation == true) {
          const response = await axios.post(`${urlSite}/signup`, {
            name      : this.name, 
            email     : this.email, 
            phone     : this.phone, 
            city      : this.city, 
            password  : this.password, 
          });
          Swal.fire({
            title: 'تم التسجيل بنجاح',
            text: "تم انشاء الحساب بنجاح :)",
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500
          });
          //window.location.href = '/login';
      } // End Condation statusValidation


    },

  
  
    async Login  () {

this.formValdatorCreate = [ ];
if(!this.email || !this.password) {
  this.formValdatorCreate.push("يرجي ملئ الحقول المطلوبة");
  this.statusValidation = false
} else {
  this.statusValidation = true;
} // End Condation Valdator

const formsData = {
  email:    this.email, 
  password: this.password, 
}
if(this.statusValidation == true) {
  const response = await axios.post(`${urlSite}/log.in`, formsData);
  if(response.data.statusCode == 1) {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'تم تسجيل الدخول بنجاح جاري تحويلك  . . . ',
      showConfirmButton: false,
      timer: 2000
    })
    localStorage.setItem("user_id", response.data.user_id);
    localStorage.setItem('acount_type' , response.data.acount_type);
    localStorage.setItem('status_auth', "authorization");
    window.location.href = '/dashbord';
    this.statusAuth = false;
  } else {
    this.statusAuth = true;
  }
}
}, // 


  

    async createSubscription  () {
      /*
      // Valdation
      this.formValdatorCreate = [ ];
      if(!this.clint || !this.plan || !this.gram || !this.note) {
        this.formValdatorCreate.push("يرجي ملئ الحقول المطلوبة");
        this.statusValidation = false
      } else {
        this.statusValidation = true;
      } // End Condation Valdator
      */


      //if(this.statusValidation == true) {
        const response = await axios.post(`${urlSite}/store.subscription`, {
            clint    : localStorage.getItem("user_id"),
            plan     : this.plan_id,
            days     : this.days,
            //gram     : this.gram,
            note     : this.note,
            // Days
            sunday   : this.sunday,
            monday   : this.monday,
            tuesday  : this.tuesday,
            wednesday : this.wednesday,
            thursday : this.thursday,
            friday   : this.friday,
            saturday : this.saturday,
            // Days
            breakfast : this.breakfast,
            lunch     : this.lunch,
            dinner    : this.dinner,
          });

          if(response) {
          this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      } // response

      //}

    }, // Store Function
  
  
  
  }, // methods








}

</script>


