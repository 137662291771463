
<template>
  <div class="">
    <!-- Card Table -->
    <div class="card">

      <form @submit.prevent="" @submit="filter()" class=" searchBox">
          <nav class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
            <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
              <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
                <i class="bx bx-menu bx-sm"></i>
              </a>
            </div>
            <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
              <div class="navbar-nav align-items-center">
                <div class="nav-item d-flex align-items-center">
                  <i class="bx bx-search fs-4 lh-0"></i>
                  <input type="text" 
                         v-model="searchBox"
                         class="form-control border-0 shadow-none" 
                         placeholder="Search..." 
                         aria-label="Search..."
                  />
                </div>
              </div>
            </div>

            <input type="date" class="form-control" v-model="filter_start">
            <input type="date" class="form-control" v-model="filter_end">


            <button class="btn btn-dark " type="submit">بحث</button>
          </nav> 
    </form>



      <h5 class="card-header"> الفواتير اليومية </h5>

        <button type="button" 
                class="btn btn-primary btn-new btn-xs" 
                data-toggle="modal" 
                data-target="#newModal" 
                style="float: left;"
                >اضافة
        </button>

        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>الاســــــــــم</th>
                <th> مبلغ التحويل </th>
                <th> البنك </th>
                <th> التاريخ </th>
                <th> الطلبية </th>
                <th> المدينة </th>
                <th> ملاحظات </th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" v-bind:key="item">
                <td>{{ index + 1  }}</td>
                <td>{{ item.clint }}</td>
                <td>{{ item.money }}</td>
                <td>{{ item.bank }}</td>
                <td>{{ item.time }}</td>
                <td>{{ item.order }}</td>
                <td>{{ item.city_name }}</td>
                <td>{{ item.note }}</td>
                <td>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.clint, item.money, item.bank, item.order, item.city, item.note)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <div>
          <!--  paginate -->
          </div>
          <br><br>
          <!--
            <paginate
              :page-count="20"
              :page-range="3"
              :margin-pages="2"
              :click-handler="clickCallback"
              :prev-text="'السابق'"
              :next-text="'التالي'"
              :container-class="'pagination'"
              :page-class="'page-item'">
            </paginate>
          -->
          <pagination v-model="page" :records="1" :per-page="1" @paginate="myCallback"/>

        </div>
        <!--  paginate -->
    </div>
    <!-- Card Table -->



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">

              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name">  العميل </label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="basic-default-name" v-model="clint" />
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> مبلغ التحويل  </label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="basic-default-name" v-model="money" />
                  </div>
              </div>  
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> البنك </label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="basic-default-name" v-model="bank" />
                  </div>
              </div>

              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name">  الطلبية  </label>
                  <div class="col-sm-10">
                    <textarea v-model="order" class="form-control" cols="5" rows="5"></textarea>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> المنطقة  </label>
                  <div class="col-sm-10">
                    <select v-model="city" id="exampleFormControlSelect1" class="form-control  form-select">
                        <option value="0"> المدينة </option>
                        <option :value="city.id" v-for="city in loopCity" v-bind:key="city">{{ city.name }}</option>
                    </select>                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> ملاحظات </label>
                  <div class="col-sm-10">
                    <textarea name="" id="" cols="3" class="form-control" rows="3" v-model="note" ></textarea>
                  </div>
              </div> 


              <li class="text-center text-danger" v-for="error in formValdatorCreate" v-bind:key="error"> {{ error  }} <br></li>


            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Create Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">
              <input type="hidden"  v-model="editID" >

              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name">  العميل </label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="basic-default-name" v-model="editClint" />
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> مبلغ التحويل  </label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="basic-default-name" v-model="editMoney" />
                  </div>
              </div>  
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> البنك </label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="basic-default-name" v-model="editBank" />
                  </div>
              </div>

              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name">  الطلبية  </label>
                  <div class="col-sm-10">
                    <textarea v-model="editOrder" class="form-control" cols="5" rows="5"></textarea>
                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> المنطقة  </label>
                  <div class="col-sm-10">
                    <select v-model="editCity" id="exampleFormControlSelect1" class="form-control  form-select">
                        <option value="0"> المدينة </option>
                        <option :value="city.id" v-for="city in loopCity" v-bind:key="city">{{ city.name }}</option>
                    </select>                  </div>
              </div>
              <div class="row mb-3">
                  <label class="col-sm-2 col-form-label" for="basic-default-name"> ملاحظات </label>
                  <div class="col-sm-10">
                    <textarea name="" id="" cols="3" class="form-control" rows="3" v-model="editNote" ></textarea>
                  </div>
              </div> 

              <li class="text-center text-danger" v-for="error in formValdatorCreate" v-bind:key="error"> {{ error  }} <br></li>


            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->

  </div> <!-- container -->   
</template>


<style>
.searchBox select, .searchBox input {
    max-width: 120px;
    margin-left: 10px;
}

label {
    display: inline-block;
    padding-right: 17px;
}

table .badge {
    font-size: 15px;
    /* font-weight: 900; */
    margin: 4px;
    padding: 6px 7px;
}
</style>






<script>
import axios from 'axios'
import Pagination from 'v-pagination-3';
//import { Link }  from '@inertiajs/inertia-vue3';
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://milddiets.com/back-end/api";
}

 export default {
  name: "DailyInvoices",
  components: {
    Pagination
  },

  data() {
    return {
      page: 1,
      // Validation
      formValdatorCreate: [],
      statusValidation: false,

      // loop Data
      loopData: [],
      loopBanks: [],
      loopCity: [],

      // Create Row
      clint : "",
      money : "",
      bank : "",
      city : "",
      order : "",
      note : "",

      // Edit Row
      editID : "",
      editClint : "",
      editMoney : "", 
      editBank : "",
      editOrder : "",
      editCity : "",
      editNote : "",

      filter_start: "",
      filter_end  : "",
      filter_bank : "",
      filter_city: "",
      
      searchBox: "",
      status: "",
    }
  },

  mounted () {
      window.document.title = " الفواتير اليومية ";
      if (!localStorage.getItem("status_auth")) {
          window.location.href = '/login';
      }
      this.getData();
  }, // mounted


  methods : {

    clickCallback: function (pageNum) {
        console.log(pageNum)
    },

    async changeStatus(event) {
      alert(event.target.data)
      const response = await axios.post(`${urlSite}/update.status`, {
        id : event.target.data,
        status : event.target.value
      })
      if(response) {
          this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم تحديث العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      }
    },
    

    filter () {
      axios.get(`${urlSite}/daily.invoices`
      + "?clint="    + this.filter_clint 
      + "&start="  + this.filter_start
      + "&end="    + this.filter_end
      + "&city="   + this.filter_city
      + "&search=" + this.searchBox
      ) 
      .then(res => {
          this.loopData = res.data.subscription
        })
    },

    async getData(pageNum=1){
      await axios.get(`${urlSite}/daily.invoices`)
        .then(res => {
          this.loopData   = res.data.subscription
          this.loopBanks  = res.data.banks
          this.loopCity   = res.data.city
        })
        
    },

    async store  () {
      // Valdation
      this.formValdatorCreate = [ ];
      if(!this.clint || !this.money || !this.bank || !this.city || !this.order) {
        this.formValdatorCreate.push("يرجي ملئ الحقول المطلوبة");
        this.statusValidation = false
      } else {
        this.statusValidation = true;
      } // End Condation Valdator


      if(this.statusValidation == true) {

        const formData = {
            clint    : this.clint,
            money    : this.money,
            bank     : this.bank,
            city     : this.city,
            order    : this.order,
            note     : this.note,
        }

        const response = await axios.post(`${urlSite}/store.daily.invoices`, formData);
        
        if(response.data.statusCode == 1) {
          this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      } // response

      }

    }, // Store Function


    // Get Data For Edit
    openEditModel (getID, getClint, getMoney, getBank, getOrder, getCity, getNote) {
      this.editID        = getID
      this.editClint     = getClint
      this.editMoney     = getMoney
      this.editBank      = getBank
      this.editOrder     = getOrder
      this.editCity      = getCity
      this.editNote      = getNote
    }, // openEditModel



    async update  () {
      const formData = {
        id : this.editID,
        muazaf   : localStorage.getItem("user_id"),
        clint    : this.editClint,
        money    : this.editMoney,
        bank     : this.editBank,
        city     : this.editCity,
        order    : this.editOrder,
        note     : this.editNote,
      }
      
      const response = await axios.post(`${urlSite}/update.daily.invoices`, formData);
      if(response.data.statusCode == 1) {
          this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      } // response

    }, // Store Function



    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "daily_invoices", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete




 }
}
</script>
   