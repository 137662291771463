
<template>
  <div class="">

    <div class="card">
                    <!-- Notifications -->
                    <h5 class="card-header">الاشعـــــــارات</h5>

                    <div class="table-responsive">
                      <table class="table table-striped table-borderless border-bottom">
                        <thead>
                          <tr>
                            <th class="text-nowrap">#</th>
                            <th class="text-nowrap">الرسالة</th>
                            <th class="text-nowrap"> المرسل</th>
                            <th class="text-nowrap"> التوقيت</th>
                          </tr>
                        </thead>
                        <tbody>

                          <tr v-for="(item, index) in loopData" :key="index">
                            <td class="text-nowrap">{{ index + 1 }}</td>
                            <td class="text-nowrap">{{ item.message }}</td>
                            <td class="text-nowrap">{{ item.username }}</td>
                            <td class="text-nowrap">{{ item.time }}</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>

                    <!-- /Notifications -->
                  </div>
  </div> <!-- container -->   
</template>


<script>
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://milddiets.com/back-end/api";
}
 export default {
  name: "Member",

  data() {
    return {
      loopData: [],
      // Create Acount
      name: "",
      email: "",
      password: "",
      // Edit Acount
      editID: "",
      editName: "",
      editEmail: "",
      editPassword: "",
    }
  },

  mounted () {
      window.document.title = " الحسابات ";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/login';
      }
      this.getData();
  }, // mounted

  methods : {
    getData(){
      axios.get(`${urlSite}/notifications/${localStorage.getItem("user_id")}`)
        .then(res => {
            this.loopData = res.data.data
        })
                
    },

    store  () {
      axios.post(`${urlSite}/store.acount`, {
        name: this.name, 
        email: this.email, 
        password: this.password, 
      })
      .then(res => {
          this.getData();
          swal({
            title: 'تم التسجيل بنجاح',
            text: "تم انشاء الحساب بنجاح :)",

            imageWidth: 128,
            imageHeight: 128,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Yes take me there',
            cancelButtonText: 'Stay on U.S.A Site',
            imageAlt: 'Custom image',
            dangerMode: false,
            timer: 4000
          });
      });
      
    },


    // Get Data For Edit
    openEditModel (getID, getName, getEmail, getPassword) {
      this.editID      = getID
      this.editName    = getName
      this.editEmail   = getEmail
      this.editPassword  = getPassword
    }, // openEditModel


    update () {
      axios.post(`${urlSite}/update.acount`, {
        id:this.editID, 
        name:this.editName, 
        email:this.editEmail,
        password:this.getPassword,
      }).then(res => {
        this.getData();
        swal({
            position: 'top-end',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            text: "تم انشاء الحساب بنجاح :)",
            showConfirmButton: false,
            timer: 2000
          })
      });
    },


    onDelete(id) {
      swal({

        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            axios.post(`${urlSite}/store.food`, {
              id   : id, 
              table: "users", 
            }).then(res => {
                this.getData();
            });
              this.$swal.fire('حــــذف !','تم حذف البيانات بنجاح','success')}
        })
    }, // onDelete






 }
}
</script>
   