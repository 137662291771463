const PlansValidation = (name) => {
    let formValdatorCreatePlans = [];
    let statusValidation = null;
    if(!name) {
      
      formValdatorCreatePlans.push("يرجي ملئ الحقول المطلوبة");
      statusValidation = "error";
    } else {
      statusValidation = "succsess";
    }
};

export default PlansValidation;