
<template>
  <div class="container">
    <!-- Card Table -->
    <div class="card">

      <form @submit.prevent="" @submit="filter()" class=" searchBox">
          <nav class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
            <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
              <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
                <i class="bx bx-menu bx-sm"></i>
              </a>
            </div>
            <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
              <div class="navbar-nav align-items-center">
                <div class="nav-item d-flex align-items-center">
                  <i class="bx bx-search fs-4 lh-0"></i>
                  <input type="text" 
                         v-model="searchBox"
                         class="form-control border-0 shadow-none" 
                         placeholder="Search..." 
                         aria-label="Search..."
                  />
                </div>
              </div>
            </div>

            <input type="date" class="form-control" v-model="filter_start">
            <input type="date" class="form-control" v-model="filter_end">


            <select v-model="filter_plan" class="form-select" >
                <option value="0"> الباقة </option>
                <option :value="plan.id" v-for="plan in loopPlans"  v-bind:key="plan">{{ plan.name }}</option>
            </select>
            <button class="btn btn-dark " type="submit">بحث</button>
          </nav> 
    </form>



      <h5 class="card-header"> الاشتـــــــــــــــــركات </h5>

        <button type="button" 
                class="btn btn-primary btn-new btn-xs" 
                data-toggle="modal" 
                data-target="#newModal" 
                style="float: left;"
                >اضافة
        </button>

        <div class="table-responsive text-nowrap">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>الاســــــــــم</th>
                <th>المدن</th>
                <th> الهاتف </th>
                <th> العمر </th>
                <th> الوزن </th>
                <th> الطول </th>
                <th>النشاط الممارس</th>
                <th>مدة الاشتراك</th>
                <th> الايام المتبقية</th>
                <th> ايام التوصيل </th>
                <th>الوجبات</th>
                <th>الاطعمه الغير مرغوب فيها</th>
                
                <th>طريق الاستلام</th>
                <th>بيانات التواصل</th>
                <th> العنوان </th>
                <th> ملاحظات </th>
                <th> التاريخ </th>
                <th> الحالة </th>
                <th>تحكم</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item.id">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.username }}  </td>
                <td> {{ item.city_name }}  </td>
                <td> {{ item.phone }}  </td>
                <td> {{ item.age }}  </td>
                <td> {{ item.weight }}  </td>
                <td> {{ item.height }}  </td>
                <td> {{ item.practiced_activity }}  </td>
                <td> {{ item.days }}  </td>
                <td> 
                  <span v-if="item.counter_day < 5" class="badge bg-label-danger">{{ item.counter_day }}  </span>
                  <span v-else class="badge bg-label-success">{{ item.counter_day }}</span>
                </td>

                <td> {{ item.delivery_days }}  </td>
                <td>{{ item.meals_name }}</td>

                <td> {{ item.meals }}  </td>
                <!--
                <td>
                    <span class="badge bg-label-primary" v-if="item.sunday == 1">الاحد</span>
                    <span class="badge bg-label-secondary" v-if="item.monday == 1">الاتنين</span>
                    <span class="badge bg-label-success" v-if="item.tuesday == 1">الثلاثاء</span>
                    <span class="badge bg-label-danger" v-if="item.wednesday == 1">الاربع</span>
                    <span class="badge bg-label-warning" v-if="item.thursday == 1">الخميس</span>
                    <span class="badge bg-label-info" v-if="item.friday == 1">الجمعه</span>
                    <span class="badge bg-label-dark" v-if="item.saturday == 1">السبت</span>
                </td>
                <td>
                    <span class="badge bg-label-primary" v-if="item.breakfast == 1">الفطـور</span>
                    <span class="badge bg-label-secondary" v-if="item.lunch == 1">الغـذاء</span>
                    <span class="badge bg-label-success" v-if="item.dinner == 1">العشـــــاء</span>
                </td>
                -->
                <td> {{ item.receiving_method }}  </td>
                <td> {{ item.delivery_data }}  </td>
                <td>  {{ item.address }} </td>
                <td> {{ item.note }}  </td>
                <td> {{ item.date }} </td>
                <td>
                  <span class="badge bg-label-primary">{{ item.operation_status_name }}</span>
                </td>
                <td>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.operation_status, item.plan, item.days, item.meals_count, item.date, item.note, item.username, item.phone, item.age, item.weight, item.height, item.practiced_activity, item.delivery_days, item.delivery_data, item.receiving_method, item.meals, item.address, item.city, item.delivery)"> 

                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
    <!-- Card Table -->



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">
              <div class="row mb-3">
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">الاســــــــــم</label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="username" />
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">رقم الهاتف</label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="phone" />
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name"> حالة الاشتراك </label>
                    <select class="form-control form-select" v-model="status" style="width: 100%;">
                        <option value="1">نشط</option>
                        <option value="2">ايقاف موقتاًً</option>
                        <option value="3">ايقاف نهائي</option>
                    </select>
                  </div>
              </div>

              <div class="row mb-3">
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">العمر</label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="age" placeholder="يرجي ادخال العمر" />
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">الوزن</label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="weight" placeholder="يرجي ادخال الوزن" />
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">الطول</label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="height" placeholder="يرجي ادخال الطول" />
                  </div>
              </div>

              <div class="row mb-3">
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name"> الباقة  </label>
                    <select v-model="plan" class="form-control form-select">
                      <option value=""></option>
                      <option :value="plan.id" v-for="plan in loopPlans" v-bind:key="plan">{{ plan.name }}</option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name"> النشاط الممارس </label>
                    <select v-model="practiced_activity" class="form-control form-select">
                      <option value=""></option>
                      <option value="خامل">خامل</option>
                      <option value="حركة بسيطة">حركة بسيطة</option>
                      <option value="رياضة متوسطة 1-3 ايام في الاسبوع">رياضة متوسطة 1-3 ايام في الاسبوع</option>
                      <option value="رياضة شديدة">رياضة شديدة</option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">مدة الاشتراك</label>
                    <select v-model="days" class="form-control form-select">
                      <option value="1">1 يوم</option>
                      <option value="2">2 يوم</option>
                      <option value="3">3 يوم</option>
                      <option value="4">4 يوم</option>
                      <option value="5">5 يوم</option>
                      <option value="6">6 يوم</option>
                      <option value="7">7 يوم</option>
                      <option value="8">8 يوم</option>
                      <option value="9">9 يوم</option>
                      <option value="10">10 يوم</option>
                      <option value="11">11 يوم</option>
                      <option value="12">12 يوم</option>
                      <option value="13">13 يوم</option>
                      <option value="14">14 يوم</option>
                      <option value="15">15 يوم</option>
                      <option value="16">16 يوم</option>
                      <option value="17">17 يوم</option>
                      <option value="18">18 يوم</option>
                      <option value="19">19 يوم</option>
                      <option value="20">20 يوم</option>
                      <option value="21">21 يوم</option>
                      <option value="22">22 يوم</option>
                      <option value="23">23 يوم</option>
                      <option value="24">24 يوم</option>
                      <option value="25">25 يوم</option>
                      <option value="26">26 يوم</option>
                      <option value="27">27 يوم</option>
                      <option value="28">28 يوم</option>
                      <option value="29">29 يوم</option>
                      <option value="30">30 يوم</option>
                    </select>
                  </div>
              </div>  


              
              <div class="row mb-3">
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name"> تاريخ  </label>
                    <input type="date" class="form-control" id="basic-default-name" v-model="date" />
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name"> ايام التوصيل  </label>
                    <select v-model="delivery_days" class="form-control">
                      <option value="من السبت - الخميس">من السبت - الخميس</option>
                      <option value="من الاحد - الخميس">من الاحد - الخميس</option>
                      <option value="من السبت - الاربع">من السبت - الاربع</option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">  المدينة  </label>
                    <select v-model="city" class="form-control" required>
                      <option value=""></option>
                      <option v-for="item in loopCity" :value="item.id" :key="item">{{ item.name }}</option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">الوجبات</label>
                    <select class="form-control" v-model="meals_count">
                      <option :value="item.id" v-for="item in loopMeals" :key="item"> {{ item.name }}</option>
                    </select>
                  </div>

                  <div class="col-sm-4">
                      <label class="col-form-label" for="basic-default-name"> طريق الاستلام </label>
                      <select class="form-control" v-model="receiving_method">
                        <option value="استـــــلام من المركز">استـــــلام من المركز</option>
                        <option value="توصيــــــل">توصيــــــل</option>
                      </select>
                    </div>

                    <div class="col-sm-4">
                      <label class="col-form-label" for="basic-default-name"> عامل التوصيل </label>
                      <select class="form-control" v-model="delivery">
                        <option></option>
                        <option v-for="item in loopDelivery" :value="item.id" :key="item"> {{ item.name }} </option>
                      </select>
                    </div>

                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name"> بيانات التوصيل </label>
                    <textarea cols="2" class="form-control" rows="2" v-model="delivery_data" ></textarea>
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">ملاحظات</label>
                    <textarea cols="2" class="form-control" rows="2" v-model="note" ></textarea>
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">المدينة + العنوان</label>
                    <textarea cols="2" class="form-control" rows="2" v-model="address" ></textarea>
                  </div>

                </div> 
      
              <!-- الايــــام  
              <div class="row mb-3">
                <label class="col-sm-2 col-form-label" for="basic-default-name"> الايام </label>
                <div class="col-sm-10 row">
                  <div class="form-check form-switch mb-2 col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="sunday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الاحد</label>
                  </div>
                  <br>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="monday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الاثنين</label>
                  </div>
                  <div class="form-check form-switch mb-2 col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="tuesday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الثلاثاء</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="wednesday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الاربع</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="thursday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الخميس</label>
                  </div>
                  <div class="form-check form-switch mb-2 col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="friday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الجمعه</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" id="defaultCheck7" checked="" v-model="saturday" value="true">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">السبت</label>
                  </div>
                </div>
              </div>
               الايــــام  
                    
              الوجبـــــات  
              <div class="row mb-3">
                <label class="col-sm-2 col-form-label" for="basic-default-name"> الوجبات </label>
                <div class="col-sm-10 row">
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="breakfast" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الفطـور</label>
                  </div>
                  <div class="form-check form-switch mb-2 col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="lunch" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الغـذاء</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="dinner" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">العشـــــاء</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="dinner" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">سناك</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="dinner" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">سلطه</label>
                  </div>
                </div>
              </div>
               الوجبـــــات  -->


               <div class="row mb-3">
                  <div class="form-check form-switch mb-2  col-sm-2">
                        <input class="form-check-input" type="checkbox" value="1" v-model="breakfast" id="meals_checked_1">
                        <label class="form-check-label" for="meals_checked_1">فطــــــور</label>
                    </div>
                    <div class="form-check form-switch mb-2  col-sm-2">
                        <input class="form-check-input" type="checkbox" value="1" v-model="lunch" id="meals_checked_2">
                        <label class="form-check-label" for="meals_checked_2">غــــــذاء</label>
                    </div>
                    <div class="form-check form-switch mb-2  col-sm-2">
                        <input class="form-check-input" type="checkbox" value="1" v-model="dinner" id="meals_checked_3">
                        <label class="form-check-label" for="meals_checked_3">عشــــــاء</label>
                    </div>
                    <div class="form-check form-switch mb-2  col-sm-2">
                        <input class="form-check-input" type="checkbox" value="1" v-model="authority" id="meals_checked_4">
                        <label class="form-check-label" for="meals_checked_4">سلطــــــه</label>
                    </div>
                    <div class="form-check form-switch mb-2  col-sm-2">
                        <input class="form-check-input" type="checkbox" value="1" v-model="snack" id="meals_checked_5">
                        <label class="form-check-label" for="meals_checked_5">سنـــــاك</label>
                  </div>
               </div>


              <!-- الاطعمه الغير مرغوب فيها  -->
              <div class="row mb-3">
                <label class="col-sm-12 col-form-label" for="basic-default-name"> الاطعمه الغير مرغوب فيها </label>
                <div class="row">
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="الحليب" v-model="meals" id="checked_1">
                      <label class="form-check-label" for="checked_1">الحليب</label>
                  </div>
                  <div class="form-check form-switch mb-2 col-sm-2">
                      <input class="form-check-input" type="checkbox" value="سمك" v-model="meals" id="checked_2">
                      <label class="form-check-label" for="checked_2">سمك</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="الدجاج" v-model="meals" id="checked_3">
                      <label class="form-check-label" for="checked_3">الدجاج</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="الفراوله" v-model="meals" id="checked_4">
                      <label class="form-check-label" for="checked_4">الفراوله</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="الصويا" v-model="meals" id="checked_5">
                      <label class="form-check-label" for="checked_5">الصويا</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="البقول" v-model="meals" id="checked_6">
                      <label class="form-check-label" for="checked_6">البقول</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="اللحم" v-model="meals" id="checked_7">
                      <label class="form-check-label" for="checked_7">اللحم</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-3">
                      <input class="form-check-input" type="checkbox" value="الفول السوداني" v-model="meals" id="checked_8">
                      <label class="form-check-label" for="checked_8">الفول السوداني</label>
                  </div>                  
                  <div class="form-check form-switch mb-2  col-sm-3">
                      <input class="form-check-input" type="checkbox" value="الباذنجان" v-model="meals" id="checked_9">
                      <label class="form-check-label" for="checked_9">الباذنجان</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="الكوسا" v-model="meals" id="checked_10">
                      <label class="form-check-label" for="checked_10">الكوسا</label>
                  </div>
                  <!--
                  <div class="form-check form-switch mb-2  col-sm-3">
                      <input class="form-check-input" type="checkbox" value="الباذنجان" v-model="meals" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الباذنجان</label>
                  </div>
                  -->
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="البيض" v-model="meals" id="checked_11">
                      <label class="form-check-label" for="checked_11">البيض</label>
                  </div>


                </div>
              </div>
              <!-- الاطعمه الغير مرغوب فيها  -->


              <li class="text-center text-danger" v-for="error in formValdatorCreate" v-bind:key="error"> {{ error  }} <br></li>


            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- Create Row -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog  modal-lg" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card-body">
              <input type="hidden"  v-model="edit_id">
              <div class="row mb-3">
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">الاســــــــــم</label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="edit_username" />
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">رقم الهاتف</label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="edit_phone" />
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name"> حالة الاشتراك </label>
                    <select class="form-control form-select" v-model="edit_status" style="width: 100%;">
                        <option value="1">نشط</option>
                        <option value="2">ايقاف موقتاًً</option>
                        <option value="3">ايقاف نهائي</option>
                    </select>
                  </div>
              </div>

              <div class="row mb-3">
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">العمر</label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="edit_age" placeholder="يرجي ادخال العمر" />
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">الوزن</label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="edit_weight" placeholder="يرجي ادخال الوزن" />
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">الطول</label>
                    <input type="text" class="form-control" id="basic-default-name" v-model="edit_height" placeholder="يرجي ادخال الطول" />
                  </div>
              </div>

              <div class="row mb-3">
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name"> الباقة  </label>
                    <select v-model="edit_plan" class="form-control form-select">
                      <option value=""></option>
                      <option :value="plan.id" v-for="plan in loopPlans" v-bind:key="plan">{{ plan.name }}</option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name"> النشاط الممارس </label>
                    <select v-model="edit_practiced_activity" class="form-control form-select">
                      <option value=""></option>
                      <option value="خامل">خامل</option>
                      <option value="حركة بسيطة">حركة بسيطة</option>
                      <option value="رياضة متوسطة 1-3 ايام في الاسبوع">رياضة متوسطة 1-3 ايام في الاسبوع</option>
                      <option value="رياضة شديدة">رياضة شديدة</option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">مدة الاشتراك</label>
                    <select v-model="edit_days" class="form-control form-select">
                      <option value="1">1 يوم</option>
                      <option value="2">2 يوم</option>
                      <option value="3">3 يوم</option>
                      <option value="4">4 يوم</option>
                      <option value="5">5 يوم</option>
                      <option value="6">6 يوم</option>
                      <option value="7">7 يوم</option>
                      <option value="8">8 يوم</option>
                      <option value="9">9 يوم</option>
                      <option value="10">10 يوم</option>
                      <option value="11">11 يوم</option>
                      <option value="12">12 يوم</option>
                      <option value="13">13 يوم</option>
                      <option value="14">14 يوم</option>
                      <option value="15">15 يوم</option>
                      <option value="16">16 يوم</option>
                      <option value="17">17 يوم</option>
                      <option value="18">18 يوم</option>
                      <option value="19">19 يوم</option>
                      <option value="20">20 يوم</option>
                      <option value="21">21 يوم</option>
                      <option value="22">22 يوم</option>
                      <option value="23">23 يوم</option>
                      <option value="24">24 يوم</option>
                      <option value="25">25 يوم</option>
                      <option value="26">26 يوم</option>
                      <option value="27">27 يوم</option>
                      <option value="28">28 يوم</option>
                      <option value="29">29 يوم</option>
                      <option value="30">30 يوم</option>
                    </select>
                  </div>
              </div>  
              
              <div class="row mb-3">
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name"> تاريخ  </label>
                    <input type="date" class="form-control" id="basic-default-name" v-model="edit_date" />
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name"> ايام التوصيل  </label>
                    <select v-model="edit_delivery_days" class="form-control">
                      <option value="من السبت - الخميس">من السبت - الخميس</option>
                      <option value="من الاحد - الخميس">من الاحد - الخميس</option>
                      <option value="من السبت - الاربع">من السبت - الاربع</option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name"> المدينة </label>
                    <select v-model="edit_city" class="form-control" required>
                      <option value=""></option>
                      <option v-for="item in loopCity" :value="item.id" :key="item">{{ item.name }}</option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">الوجبات</label>
                    <select class="form-control" v-model="edit_meals_count">
                      <option :value="item.id" v-for="item in loopMeals" :key="item"> {{ item.name }}</option>
                    </select>
                  </div>

                  <div class="col-sm-4">
                      <label class="col-form-label" for="basic-default-name"> طريق الاستلام </label>
                      <select class="form-control" v-model="edit_receiving_method">
                        <option value="استـــــلام من المركز">استـــــلام من المركز</option>
                        <option value="توصيــــــل">توصيــــــل</option>
                      </select>
                  </div>
                  
                  <div class="col-sm-4">
                      <label class="col-form-label" for="basic-default-name">عامل التوصيل</label>
                      <select class="form-control" v-model="edit_delivery">
                        <option></option>
                        <option v-for="item in loopDelivery" :value="item.id" :key="item"> {{ item.name }} </option>
                      </select>
                    </div>

                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name"> بيانات التوصيل </label>
                    <textarea cols="2" class="form-control" rows="2" v-model="edit_delivery_data" ></textarea>
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">ملاحظات</label>
                    <textarea cols="2" class="form-control" rows="2" v-model="edit_note" ></textarea>
                  </div>
                  <div class="col-sm-4">
                    <label class="col-form-label" for="basic-default-name">المدينة + العنوان</label>
                    <textarea cols="2" class="form-control" rows="2" v-model="edit_address" ></textarea>
                  </div>

                </div> 
      
              <!-- الايــــام  
              <div class="row mb-3">
                <label class="col-sm-2 col-form-label" for="basic-default-name"> الايام </label>
                <div class="col-sm-10 row">
                  <div class="form-check form-switch mb-2 col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="sunday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الاحد</label>
                  </div>
                  <br>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="monday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الاثنين</label>
                  </div>
                  <div class="form-check form-switch mb-2 col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="tuesday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الثلاثاء</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="wednesday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الاربع</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="thursday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الخميس</label>
                  </div>
                  <div class="form-check form-switch mb-2 col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="friday" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الجمعه</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" id="defaultCheck7" checked="" v-model="saturday" value="true">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">السبت</label>
                  </div>
                </div>
              </div>
               الايــــام  
                    
              الوجبـــــات  
              <div class="row mb-3">
                <label class="col-sm-2 col-form-label" for="basic-default-name"> الوجبات </label>
                <div class="col-sm-10 row">
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="breakfast" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الفطـور</label>
                  </div>
                  <div class="form-check form-switch mb-2 col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="lunch" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الغـذاء</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="dinner" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">العشـــــاء</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="dinner" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">سناك</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-4">
                      <input class="form-check-input" type="checkbox" value="true" v-model="dinner" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">سلطه</label>
                  </div>
                </div>
              </div>
   




              <div class="row mb-3">
                <label class="col-sm-12 col-form-label" for="basic-default-name"> الاطعمه الغير مرغوب فيها </label>
                <div class="row">
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="الحليب" v-model="meals" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الحليب</label>
                  </div>
                  <div class="form-check form-switch mb-2 col-sm-2">
                      <input class="form-check-input" type="checkbox" value="سمك" v-model="meals" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">سمك</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="الدجاج" v-model="meals" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الدجاج</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="الفراوله" v-model="meals" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الفراوله</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="الصويا" v-model="meals" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الصويا</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="البقول" v-model="meals" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">البقول</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-2">
                      <input class="form-check-input" type="checkbox" value="اللحم" v-model="meals" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">اللحم</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-3">
                      <input class="form-check-input" type="checkbox" value="الفول السوداني" v-model="meals" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الفول السوداني</label>
                  </div>                  
                  <div class="form-check form-switch mb-2  col-sm-3">
                      <input class="form-check-input" type="checkbox" value="الباذنجان" v-model="meals" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الباذنجان</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-3">
                      <input class="form-check-input" type="checkbox" value="الكوسا" v-model="meals" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الكوسا</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-3">
                      <input class="form-check-input" type="checkbox" value="الباذنجان" v-model="meals" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">الباذنجان</label>
                  </div>
                  <div class="form-check form-switch mb-2  col-sm-3">
                      <input class="form-check-input" type="checkbox" value="البيض" v-model="meals" id="flexSwitchCheckChecked_1">
                      <label class="form-check-label" for="flexSwitchCheckChecked_1">البيض</label>
                  </div>


                </div>
              </div>
               -->





            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>

        </form>
      </div>
    </div>
    <!-- Create Row -->

  </div> <!-- container -->   
</template>


<style  scoped>


.searchBox select, .searchBox input {
    max-width: 120px;
    margin-left: 10px;
}

label {
    display: inline-block;
    font-weight: bold;
    font-family: "IcoFont";
    padding-right: 0px;
    
}

.form-switch .form-check-input {
    width: 2em;
    margin-left: -1em;
}
table .badge {
    font-size: 15px;
    /* font-weight: 900; */
    margin: 4px;
    padding: 6px 7px;
}
</style>






<script>
import axios from 'axios'
import pagination from 'laravel-vue-pagination'
import PlansValidation from '../validation/PlansValidation.js'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://milddiets.com/back-end/api";
}

 export default {
  name: "Payment",
  components:{
            pagination
        },
  data() {
    return {
      // Validation
      formValdatorCreate: [],
      statusValidation: false,

      // loop Data
      loopData:   [],
      loopClints: [],
      loopPlans:  [],
      loopBanks:  [],
      loopCity:   [],
      loopMeals:  [],
      operationStatus: [],
      

      // Create Row
      status: "",
      plan : "",
      days : "",
      meals_count: "",
      date : "",
      note : "",
      username : "",
		  phone : "",
		  age : "",
	   	weight : "",
	  	height : "",
	  	practiced_activity : "",
	  	delivery_days : "",
      delivery_data : "",
      delivery      : "",
      receiving_method : "",
      address: "",
      city: '',
	  	meals : [],
      // The Days
      /*
      sunday : "",
      monday : "",
      tuesday : "",
      wednesday : "",
      thursday : "",
      friday : "",
      saturday : "",
       */
      // The meals
      breakfast : "",
      lunch : "",
      dinner : "",
      snack : "",
      authority : "",
     




       

      // Edit Row
      edit_id : "",
      edit_status: "",
      edit_plan : "",
      edit_days : "",
      edit_meals_count : "",
      edit_date : "",
      edit_note : "",
      edit_username : "",
		  edit_phone : "",
		  edit_age : "",
      edit_weight : "",
	  	edit_height : "",
	  	edit_practiced_activity : "",
	  	edit_delivery_days : "",
      edit_delivery_data : "",
      edit_delivery      : "",

      edit_receiving_method : "",
      edit_address: "",
	  	edit_meals : [],
      edit_city: '',

      edit_breakfast : "",
      edit_lunch : "",
      edit_dinner : "",
      edit_snack : "",
      edit_authority : "",    


      filter_start: "",
      filter_end  : "",
      filter_bank : "",
      filter_plan : "",
      filter_clint: "",
      filter_city: "",
      
      searchBox: "",
      status: "",
    }
  },

  mounted () {
      window.document.title = "الاشتـــــــــــــــــركات"
      if (!localStorage.getItem("status_auth")) {
          window.location.href = '/login';
      }
      this.getData();
  }, // mounted


  methods : {

    async changeStatus(event) {
      const response = await axios.post(`${urlSite}/update.subscription`, {
        id : event.target.data,
        status : event.target.value
      })
      if(response) {
          this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم تحديث العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      }
    },
    

    filter () {
      axios.get(`${urlSite}/subscription`
      + "?clint="    + this.filter_clint 
      + "&plan="   + this.filter_plan
      + "&start="  + this.filter_start
      + "&end="    + this.filter_end
      + "&city="   + this.filter_city
      + "&search=" + this.searchBox
      ) 
      .then(res => {
          this.loopData = res.data.subscription;
        })
    },

    getData(page=1){
      axios.get(`${urlSite}/subscription`)
        .then(res => {
          this.loopData   = res.data.subscription
          this.loopClints = res.data.clints
          this.loopPlans  = res.data.plans
          this.loopBanks  = res.data.banks
          this.operationStatus = res.data.operation_status
          this.loopCity   = res.data.city
          this.loopMeals = res.data.meals
          this.loopDelivery = res.data.delivery
        })
        
    },

    async store  () {
        const response = await axios.post(`${urlSite}/store.subscription`, {
            //clint    : this.clint,
            plan     : this.plan,
            status   : this.status,
            days     : this.days,
            meals_count : this.meals_count,
            date     : this.date,
            note     : this.note,
            username : this.username,
		        phone : this.phone,
		        age : this.age,
	   	      weight : this.weight,
	  	      height : this.height,
	  	      practiced_activity : this.practiced_activity,
	  	      delivery_days : this.delivery_days,
	  	      meals : this.meals,
            delivery_data : this.delivery_data,
            delivery      : this.delivery,
            receiving_method : this.receiving_method,
            address: this.address,
            city: this.city,

            breakfast :this.breakfast,
            lunch     : this.lunch,
            dinner    : this.dinner,
            snack     : this.snack,
            authority : this.authority,
      
            /*
            // Days
            sunday   : this.sunday,
            monday   : this.monday,
            tuesday  : this.tuesday,
            wednesday : this.wednesday,
            thursday : this.thursday,
            friday   : this.friday,
            saturday : this.saturday,
            // Days
            breakfast : this.breakfast,
            lunch     : this.lunch,
            dinner    : this.dinner,
            */
          });

          if(response) {
          this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام العملية بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      } // response

      

    }, // Store Function


    // Get Data For Edit
    openEditModel (id, status, plan, days, meals_count, date, note, username, phone, age, weight, height, practiced_activity, delivery_days, delivery_data, receiving_method, meals, address, city, delivery) {
      this.edit_id = id
      this.edit_status = status
      this.edit_plan = plan
      this.edit_days = days
      this.edit_meals_count = meals_count
      this.edit_date = date
      this.edit_note = note
      this.edit_username = username
		  this.edit_phone = phone
		  this.edit_age = age
      this.edit_weight = weight
	  	this.edit_height = height
	  	this.edit_practiced_activity = practiced_activity
	  	this.edit_delivery_days = delivery_days
      this.edit_delivery_data = delivery_data
      this.edit_receiving_method = receiving_method
	  	this.edit_meals = meals
      this.edit_address = address
      this.edit_city  = city
      this.delivery   = delivery

      
 
    }, // openEditModel
    

    async update  () {
          const response = await axios.post(`${urlSite}/update.subscription`, {
            id       : this.edit_id,
            status   : this.edit_status,
            meals_count : this.edit_meals_count,
            plan     : this.edit_plan,
            days     : this.edit_days,
            date     : this.edit_date,
            note     : this.edit_note,
            username : this.edit_username,
		        phone : this.edit_phone,
		        age : this.edit_age,
	   	      weight : this.edit_weight,
	  	      height : this.edit_height,
	  	      practiced_activity : this.edit_practiced_activity,
	  	      delivery_days : this.edit_delivery_days,
	  	      meals : this.edit_meals,
            delivery_data : this.edit_delivery_data,
            receiving_method : this.edit_receiving_method,
            address: this.edit_address,
            city: this.city,

          });
          if(response) {
          this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 3000
          })
      } // response

    }, // Store Function


    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${urlSite}/destroy`, {
          id   : id, 
          table: "subscriptions", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete






 }
}
</script>
   