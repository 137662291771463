import { createRouter, createWebHistory, createWebHashHistory,  RouteRecordRaw } from "vue-router";

//import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/Project/HomeView.vue";
import Plan from "../views/Project/Plan.vue";
import Food from "../views/Food.vue";

import Plans from "../views/Plans.vue";
import CategoryPlans from "../views/CategoryPlans.vue";
import Payment from "../views/Payment.vue";
import Dashbord from "../views/Dashbord.vue";
import Subscription from "../views/Subscription.vue";
import toDay from "../views/toDay.vue";
import DailyInvoices from "../views/DailyInvoices.vue";
import Orders from "../views/Orders/Orders.vue";
import ClintOrders from "../views/Orders/ClintOrders.vue";

import Team from "../views/Team.vue";
import Contact from "../views/Contact.vue";
import Service from "../views/Service.vue";

// Member
import SignUp from "../views/Member/SignUp.vue";
import Login from "../views/Member/Login.vue";
import ForgotPassword from "../views/Member/ForgotPassword.vue";
import Profile from "../views/Member/Profile.vue";
import Notifications from "../views/Member/Notifications.vue";
import Member from "../views/Member/Member.vue";

// Orders
import DeliveryCases from "../views/Orders/DeliveryCases.vue";
import City from "../views/Orders/City.vue";


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/Food",
    name: "Foods",
    component: Food,
  },
  {
    path: "/Dashbord",
    name: "Dashbord",
    component: Dashbord,
  },
  
  
  { path: "/plans", name: "Plans", component: Plans}, 
  { path: "/category.plans", name: "CategoryPlans", component: CategoryPlans}, 
  { path: "/plan/:category", name: "Plan", component: Plan}, 


  { path: "/payment", name: "Payment", component: Payment, meta: { title: 'Home page' }}, 
  { path: "/subscription", name: "Subscription", component: Subscription, meta: { title: 'Home page' }}, 
  { path: "/to.day", name: "toDay", component: toDay, meta: { title: 'Home page' }}, 
  { path: "/daily.invoices", name: "DailyInvoices", component: DailyInvoices}, 
  { path: "/teams", name: "team", component: Team}, 
  { path: "/contact", name: "contact", component: Contact}, 
  { path: "/service", name: "service", component: Service}, 
  
  
  { path: "/signUp", name: "signup", component: SignUp}, 
  { path: "/login", name: "login", component: Login}, 
  { path: "/forgotPassword", name: "ForgotPassword", component: ForgotPassword}, 
  { path: "/profile", name: "Profile", component: Profile}, 
  { path: "/notifications", name: "Notifications", component: Notifications}, 
  { path: "/member/:type", name: "Member", component: Member}, 
  
  
  { path: "/delivery.cases", name: "DeliveryCases", component: DeliveryCases}, 
  { path: "/orders", name: "orders", component: Orders}, 
  { path: "/clint.orders", name: "ClintOrders", component: ClintOrders}, 
  { path: "/city", name: "City", component: City}, 

];



const router = createRouter({
  history: createWebHashHistory(),
  //history: createWebHistory(process.env.BASE_URL),
  routes,
});



export default router;



